export const getItemService = async ({ barCode }) => {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    try {
        const resultGetItem = await window.fetch(`${requestPath}/back/routes/item.php?${new URLSearchParams({ barCode }).toString()}`, {
            credentials: 'include'
        })
        const { result, message, error_msg } = await resultGetItem.json()
        return { result, message, error_msg }
    } catch (error) {
        console.log(error)
    }
}