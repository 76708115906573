export const getPointOfIssueCodes = async ({ BPLID }) => {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultGetPointOfIssueCodes = await window.fetch(`${requestPath}/back/routes/pointofIssueCodes.php?${new URLSearchParams({ BPLID }).toString()}`, {
        credentials: 'include'
    })

    const { result, message, error_msg } = await resultGetPointOfIssueCodes.json()

    return { result, message, error_msg }

}