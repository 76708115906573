import React, { useContext, useEffect, useState } from 'react'
import { Row, Tab, Col, Nav } from 'react-bootstrap'
import TabsComponent from './TabsComponent'
import { withRouter, useLocation } from 'react-router-dom'
import PickingContext from '../context/pickingContext'
import { useCookies } from 'react-cookie'
import globalContext from '../globalcontext/globalContext'

export default withRouter(function Picking({ history }) {

  const [cookies] = useCookies()
  const { pathname } = useLocation()

  const { pickingDetail = [], setPickingDetail, setSupplierData, setLinesForDocumentLines } = useContext(PickingContext)
  const { userData = false, setIslogedInHandler } = useContext(globalContext)

  const role = cookies?.roleUser

  const [activeTab, setActiveTab] = useState('')

  useEffect(() => {
    if (!!cookies.BPLName && !!cookies.B1SESSION && !!cookies.ROUTEID && !!cookies?.roleUser) setIslogedInHandler(true)
    else setIslogedInHandler(false)
  }, [cookies])

  useEffect(() => {
    setActiveTab(pathname.replace("/", '').split("/", 1)[0])
  }, [pathname])

  // history.listen((location, action) => {
  //   // location is an object like window.location
  //   // action: push-replace
  //   // location: all the things of location 
  //   setActiveTab(pathname.replace("/", '').split("/", 1)[0])
  // })


  useEffect(() => {

  }, [userData])

  return (
    <>
      {
        userData &&
        <div className="mt-4">
          <TabsComponent>
            <Tab.Content>
              <Row className="mx-0 rounded my-1 py-1 px-3" style={{
                backgroundColor: "#fff",
                boxShadow: "0 1rem 3rem rgba(0, 0, 0, .175)!important"
              }}>
                <Nav variant="pills"
                  className="flex-row"
                  onSelect={e => {
                    if (!e.includes("consultas")) history.push(`/${e}`)
                    setActiveTab(e)
                  }}
                >
                  {
                    role !== "2" ?
                      <>
                        <Nav.Item>
                          <Nav.Link
                            className="algo"
                            eventKey="picking"
                            className="btn btn-outline-primary btn-sm mx-1"
                            active={activeTab.includes("picking")}
                          // onClick={() => history.push("/picking")}
                          >Picking</Nav.Link>
                        </Nav.Item>
                        {/* <Nav.Item>
                          <Nav.Link
                            eventKey="etiquetas"
                            className="btn btn-outline-primary btn-sm mx-1"
                            active={activeTab.includes("etiquetas")}
                          onClick={() => history.push("/etiquetas")}
                          >Etiquetas</Nav.Link>
                        </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link
                            eventKey="consultas"
                            active={activeTab.includes("consultas")}
                            className="btn btn-outline-primary btn-sm mx-1"
                            onClick={e => {
                              history.push("/consultasExit")
                              e.preventDefault()
                              setPickingDetail([])
                              setSupplierData({})
                              setLinesForDocumentLines({})
                            }}>Edición</Nav.Link>
                        </Nav.Item>
                      </>
                      :
                      <>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="consultas"
                            active={activeTab.includes("consultas")}
                            className="btn btn-outline-primary btn-sm mx-1"
                            onClick={e => {
                              history.push("/consultas")
                              e.preventDefault()
                              setPickingDetail([])
                              setSupplierData({})
                              setLinesForDocumentLines({})
                            }}>Consulta</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="validacion"
                            active={activeTab.includes("validacion")}
                            disabled={pickingDetail.length === 0 || activeTab.includes("etiquetas")}
                            className="btn btn-outline-primary btn-sm mx-1"
                          >
                            Validación
                          </Nav.Link>
                        </Nav.Item>
                        {
                          ( pathname?.includes("etiquetas")) &&
                          <Nav.Item className="mt-2 mt-md-0">
                            <Nav.Link
                              eventKey="etiquetas"
                              active={activeTab.includes("etiquetas")}
                              disabled={pickingDetail.length === 0}
                              className="btn btn-outline-primary btn-sm mx-1"
                            >
                              Etiquetas
                            </Nav.Link>
                          </Nav.Item>
                        }
                      </>
                  }
                </Nav>
              </Row>
            </Tab.Content>
          </TabsComponent>
        </div>
      }
    </>
  )
})