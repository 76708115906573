import React from 'react'
import esES from 'antd/lib/locale/es_ES'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { Container } from 'react-bootstrap'
import PickingServicesContext from './context/pickingServicesContext'
import GlobalContextServices from './globalcontext/globalContextServices'
import LoginForm from './components/LoginForm'
import ConsultTab from './components/consultTab/ConsultTab'
import ConsultTabExit from './components/consultTab/ConsultTabExit'

import PickingTab from './components/pickingTab/PickingTab'
import PickingTabEdit from './components/pickingTab/PickingTabEdit'

import ValidationTab from './components/validationTab/index'
import ValidationTabEgreso from './components/validationTab/indexEgreso'

import AppLayout from './components/AppLayout'
import PrivateRoute from './components/PrivateRoute'
import { CookiesProvider } from "react-cookie"
import NavbarComponent from './components/NavbarComponent'
import TabEtiquetas from './components/etiquetteTab'
import { HashRouter } from 'react-router-dom'

function Routes() {

    return (
        <HashRouter basename="/">
            <Switch>
                <CookiesProvider>
                    <GlobalContextServices>
                        <PickingServicesContext>
                            <ConfigProvider locale={esES}>
                                <NavbarComponent />
                                <Container>
                                    <AppLayout>
                                        <PrivateRoute path='/consultas/:fDate?/:eDate?/:typeSearch?'  role="2"  exact component={ConsultTab} />
                                        <PrivateRoute path='/consultasExit/:fDate?/:eDate?/:typeSearch?'  role="1"  exact component={ConsultTabExit} />
                                        <PrivateRoute path='/validacion' role="2" exact component={ValidationTab} />
                                        <PrivateRoute path='/validacionEgreso' role="2" exact component={ValidationTabEgreso} />
                                        <PrivateRoute path='/editar/:code' role="1" exact component={PickingTabEdit} />
                                        <PrivateRoute path='/etiquetas' role="2" exact component={TabEtiquetas} />
                                        <PrivateRoute path='/picking' role="1" exact component={PickingTab} />
                                        <Route path='/' exact component={LoginForm} />
                                    </AppLayout>
                                </Container>
                            </ConfigProvider>
                        </PickingServicesContext>
                    </GlobalContextServices>
                </CookiesProvider>
            </Switch>
        </HashRouter>
    )
}

export default Routes