import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import GlobalContext from './globalContext'


function GlobalContextServices({ children }) {

    const [cookies] = useCookies({})
    const [userData, setUserData] = useState(false)

    const setIslogedInHandler = (islogedIn) => {
        setUserData(islogedIn)
    }

    useEffect(() => {
        if (!!cookies.BPLName && !!cookies.B1SESSION && !!cookies.ROUTEID && !!cookies?.roleUser) {
            setUserData(true)
        }

        if (!!cookies?.BPLName == false && !!cookies.B1SESSION == false && !!cookies.ROUTEID == false && !!cookies?.roleUser == false) {
            setUserData(false)
        }
    }, [])

    return (
        <GlobalContext.Provider
            value={{
                userData,
                setIslogedInHandler
            }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalContextServices