import React, { useContext, useState } from 'react'
import { Row, Button } from 'react-bootstrap'
import PickingContext from '../../context/pickingContext'
import ModalComponent from '../ModalComponent'
import FormGetSupplier from './FormGetSupplier'

function ModalSupplier({ setModalIsVisibleHandler, showModal }) {

    const { setSupplierData } = useContext(PickingContext)

    const [resultBusiness, setResultBusiness] = useState([])

    const setResultBusinessHandler = (arrayBusiness = []) => {
        setResultBusiness(arrayBusiness)

    }
    return (
        <>
            <Button
                variant="primary"
                onClick={() => setModalIsVisibleHandler(true)}>
                Proveedor
            </Button>
            <ModalComponent
                modalIsVisible={showModal}
                handleClose={() => setModalIsVisibleHandler(false)}
                showConfirmButton={false}
            >
                <FormGetSupplier setResultBusinessHandler={setResultBusinessHandler} />
                {
                    resultBusiness.length > 0 &&
                    <>
                        <p>Proveedores encontrados</p>
                        <div className="overflow-auto" style={{ minHeight: "50px" }}>
                            {
                                resultBusiness?.map(business => <Row className="justify-content-between border rounded mx-0 px-4 my-1" key={business.CardName} >
                                    <p className="mb-0 text-truncate col-6" title={business.CardName}>Nombre Cliente: {business.CardName.substring(0, 25)}</p>
                                    <p className="mb-0 col-4">Código Cliente: {business.CardCode}</p>
                                    <div><i className="fas fa-check text-primary" role="button" onClick={() => {
                                        setSupplierData(business)
                                        setModalIsVisibleHandler()
                                        setResultBusinessHandler()

                                     
                                    }}></i></div>
                                    
                                </Row>)
                            }
                        </div>
                    </>
                }
            </ModalComponent>
            
        </>
    )
}

export default ModalSupplier