import React, { useContext, useEffect, useRef, useState } from 'react'
import { Row, Form, Col, InputGroup } from 'react-bootstrap'
import PickingContext from '../../context/pickingContext'
import ToastComponent from '../ToastComponent'


function FormGetItem({ setLoadingTable }) {

    const { searchType, setSearchType, processInputPicking } = useContext(PickingContext)
    const pickingInput = useRef(null)
    const pickingQuantityInput = useRef(null)
    const toastRef = useRef(null)

    const [toastMessage, setToastMessage] = useState({})
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (searchType === 'cod' || searchType === 'cant') {
            pickingInput?.current?.focus()
        }
    }, [searchType])


    const getItemHandler = async (e) => {
        if (e.type !== "paste") e.preventDefault()
        else {
            pickingInput.current.value = e.clipboardData.getData('Text').trim()
            pickingInput.current.select()
        }
        if (!!searchType === false) {
            setShow(true)
            return
        }

        try {
            if (searchType === 'cod') {
                setLoadingTable(true)
                processInputPicking({ pickingValue: e.type === "paste" ? e?.clipboardData?.getData('Text') : pickingInput?.current?.value })
                setLoadingTable(false)
                pickingInput?.current?.select()
            }
            if (searchType === 'cant') {
                setLoadingTable(true)
                if (!!pickingQuantityInput.current.value === false || pickingQuantityInput.current.value == "0") {
                    setToastMessage({ message: 'El campo cantidad no puede estar vacio', title: 'Ocurrió un error', color: 'bg-danger' })
                    setShow(true)
                    setLoadingTable(false)
                    pickingQuantityInput.current.focus()
                    return
                }
                if (Number.isNaN(Number(pickingQuantityInput.current.value))) {
                    setLoadingTable(true)
                    setToastMessage({ message: 'Por favor ingrese un número', title: 'Ocurrió un error', color: 'bg-danger' })
                    setShow(true)
                    pickingQuantityInput.current.select()
                    setLoadingTable(false)
                    pickingQuantityInput.current.focus()
                    return
                }
                processInputPicking({
                    pickingValue: e.type === "paste" ? e.clipboardData.getData('Text') : pickingInput?.current?.value, pickingQuantity: Number(pickingQuantityInput.current.value)
                })
                setLoadingTable(false)
            }
            pickingInput?.current?.select()
        } catch (error) {
            setLoadingTable(false)
            console.log(error)
        }
    }

    return (
        <>
            <ToastComponent
                show={show}
                setShow={() => setShow(false)}
                message={toastMessage?.message}
                title={toastMessage?.title}
                color={toastMessage?.color}
                reference={toastRef}
            />
            <Form onSubmit={getItemHandler}>
                <Row className="justify-content-around align-items-center border mb-3 mx-0 p-3">
                    <Col md={3}>
                        <fieldset>
                            <Form.Group as={Row} className="mb-0">
                                <Col sm={10}>
                                    <Form.Check
                                        type="radio"
                                        label="Cod. Barra"
                                        name="formHorizontalRadios"
                                        id="formHorizontalRadios1"
                                        value="cod"
                                        checked={searchType === 'cod'}
                                        onChange={(e) => setSearchType(e.target.value.trim())}
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Cod. Barra + Cantidad"
                                        name="formHorizontalRadios"
                                        id="formHorizontalRadios2"
                                        value="cant"
                                        onChange={(e) => setSearchType(e.target.value.trim())}
                                    />
                                </Col>
                            </Form.Group>
                        </fieldset>
                    </Col>
                    <Col md={6}>
                        <InputGroup>
                            <Form.Control ref={pickingInput}
                                placeholder="Cod. de Barra"
                                className="shadow-none"
                                onPaste={searchType !== 'cant' && getItemHandler}
                            />
                            {
                                searchType === 'cod' ?
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <i className="fas fa-search"></i>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    : searchType === 'cant' ?
                                        <Form.Control ref={pickingQuantityInput} placeholder="Cant" className="shadow-none col-2" onKeyPress={e => (e.nativeEvent.key === 'Enter' || e.nativeEvent.charCode === 13) && getItemHandler(e)
                                        }
                                            onPaste={getItemHandler}
                                        />
                                        :
                                        null
                            }
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default FormGetItem