import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"
import { useContext } from "react"
import PickingContext from "../context/pickingContext"

export function useFormatTransferOrder({ pickingDetail = [], selectedDestinyWarehouse = {}, selectedOriginWarehouse = '', selectedPointOfIssueCode = '' }) {

    const dictionaryProjectCode = {
        DM: 'Motos',
        DH: 'Hogar',
        DR: 'Repuestos',
        DB: 'Bikes',
        DS: 'Servicio_Postventa',
    }

    const [cookies] = useCookies()
    const [formatedTransferOrder, setFormatedTransferOrder] = useState({})

    const {linesForDocumentLines } = useContext(PickingContext)

    const lines = pickingDetail.filter(article => article.articleStatus === '1')
    .map(line => ({ ItemCode: line?.ItemCode,Quantity: line?.articleQuantity ,WarehouseCode: `${Number(selectedOriginWarehouse || 0) + 500}`, FromWarehouseCode: selectedOriginWarehouse,BaseType:1250000001, ProjectCode: dictionaryProjectCode[line?.U_Division] }))
    console.log('lines', lines);

    const result = Object.values(linesForDocumentLines).map(obj => ({
        ...obj,
     "WarehouseCode":lines.WarehouseCode,
     "FromWarehouseCode": lines.FromWarehouseCode
   }));
    console.log('result', result);


    useEffect(() => {
        
      
        setFormatedTransferOrder({
            CardCode: selectedDestinyWarehouse?.CardCode,
            U_PedidoVTEX: pickingDetail[0]?.pickingCode,
            BPLID: cookies?.BPLID,
            BPLName: cookies?.BPLName,
            PointOfIssueCode: selectedPointOfIssueCode,
            FromWarehouse: selectedOriginWarehouse,
            ToWarehouse: `${Number(selectedOriginWarehouse || 0) + 500}`,
            StockTransferLines: Object.values(linesForDocumentLines).map(obj => ({
                ...obj,
             "WarehouseCode":lines.WarehouseCode,
             "FromWarehouseCode": lines.FromWarehouseCode
           })),

        })
    }, [pickingDetail, selectedDestinyWarehouse, cookies,linesForDocumentLines])

    return { formatedTransferOrder }
}

