import { getPickingDetails } from './services/getPickingDetails'
import { useHistory } from 'react-router-dom'
import PickingContext from '../../context/pickingContext'
import { useContext, useState } from 'react'
import ModalCancelPicking from './ModalCancelPicking'
import { getPickings } from './services/getPickings'
import { getPickingByDocEntry } from './services/getPickingByDocEntry'
import ModalShowSavedicking from './ModalShowSavedicking'
import Notiflix from 'notiflix'


function ActionsForTableConsult({ rowData, }) {

    const history = useHistory()

    const { setPickingDetail, setCancelPicking, setSelectedPicking } = useContext(PickingContext)
    const [cancelPickingDetail, setCancelPickingDetail] = useState({})
    const [savedPickingDetail, setSavedPickingDetail] = useState({})


 



    const getPickingDetailsHandler = async (code) => {
        const { result = [] } = await getPickingDetails({ pickingCode: code })
        setPickingDetail(result)
        setSelectedPicking(rowData)
        if (   rowData.pickingType === "Ingreso") {
            history.push("/validacion")
        } else {
            history.push("/validacionEgreso")
        }
    }

    const getPickingDetailsEtiquetas = async (code) => {
        const { result = [] } = await getPickingDetails({ pickingCode: code })
        setPickingDetail(result)
        if (   rowData.pickingType === "Ingreso") {
             history.push("/etiquetas") 
        } 
    }



    return (
        <div className="row flex-nowrap mx-0 align-items-center justify-content-center">
            {
                rowData.pickingStatus === "Pendiente" ? <div>
                    <i className="fas fa-edit text-primary pt-2 mx-1"
                        title="Validar"
                        style={{ fontSize: '15px' }}
                        role="button"
                        onClick={() =>getPickingDetailsHandler(rowData.pickingCode)}
                    ></i>
                    <i
                        className="fas fa-times text-danger pt-2 mx-1"
                        title="Cancelar pedido"
                        style={{ fontSize: '15.3px' }}
                        role="button"
                        onClick={() => setCancelPicking({ pickingCode: rowData.pickingCode })}
                    ></i>
                    
                </div>
                    : 
                    (rowData.pickingStatus !== "Pendiente") &&
                    
                    <span
                        role="button"
                        onClick={async () => {
                            if (rowData.pickingStatus === "Cancelado") {
                                Notiflix.Block.dots('#tab-container', 'Consultado Picking...')
                                const { result = [], message = "" } = await getPickings({ pickingCode: rowData.pickingCode, pickinHeader: true })
                                if (message.includes("Successfully") && result.length > 0) setCancelPickingDetail(result[0])
                                Notiflix.Block.remove('#tab-container')
                            }
                            
                            if (rowData.pickingStatus === "Ingresado") {
                                Notiflix.Block.dots('#tab-container', 'Consultado Ingreso...')
                                if (rowData?.pickingType === "Ingreso") {
                                    const { result = [] } = await getPickingByDocEntry({ DocEntry: rowData.entrySAP, DocNum: rowData.DocNum,pickingType: rowData?.pickingType  })
                                    if (result.length > 0) setSavedPickingDetail(result[0])
                                }
                                if (rowData?.pickingType === "Egreso") {
                                    const { result = [] } = await getPickingByDocEntry({ DocEntry: rowData.entrySAP, DocNum: rowData.DocNum,pickingType: rowData?.pickingType })
                                    if (result.length > 0) setSavedPickingDetail({ ...result[0], DocumentLines: result[0].StockTransferLines, pickingStatus: rowData?.pickingType })
                                }
                                Notiflix.Block.remove('#tab-container')
                            }
                        }}>
                        
                        <i className="far fa-eye text-info pt-2 mx-1" title="Ver"                         role="button"
 style={{ fontSize: '15px' }}></i>
                      

                        
                    </span>
                    
            }{
                rowData.pickingStatus !== "Pendiente" &&  rowData.pickingType !== "Egreso" &&
                
                <i className="fa fa-barcode pt-1 mx-1" title="Imprimir Etiquetas"                         role="button"
                style={{ fontSize: '20px' }}  onClick={() =>getPickingDetailsEtiquetas(rowData.pickingCode)}></i>
            }
              


            <ModalCancelPicking cancelPickingDetail={cancelPickingDetail} setCancelPickingDetail={setCancelPickingDetail} />
            <ModalShowSavedicking savedPickingDetail={savedPickingDetail} setSavedPickingDetail={setSavedPickingDetail} />
        </div>
    )
}

export default ActionsForTableConsult