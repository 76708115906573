import { useContext } from 'react'
import { Button, Row } from 'react-bootstrap'
import PickingContext from '../../context/pickingContext'
import { exportToExcel } from './services/exportToExcel'

export default function ButtonExportToExcel() {

    const { pickingDetail = [] } = useContext(PickingContext)

    const exportToExcelHandler = () => {
        exportToExcel(pickingDetail)
    }

    return (
        <Row className="justify-content-end mx-0 text-success">
            <span role="button" onClick={exportToExcelHandler}>Excel <i className="far fa-file-excel"></i> </span>
        </Row>
    )
}
