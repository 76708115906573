import { useContext, useEffect, useState } from 'react'
import PickingContext from '../../context/pickingContext'
import jsPDF from 'jspdf'
import jsBarcode from 'jsbarcode'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { getItemService } from '../pickingTab/services/getItemService'

function TabEtiquetas() {

    const history = useHistory()
    const { pickingDetail = [],setPickingDetail } = useContext(PickingContext)

    const getDetailSAP = async (filteredPickingDetail) => {
        for (const picking of filteredPickingDetail) {
            const { result = [], error_msg = "" } = await getItemService({ barCode: picking.articleBarCode })
            // console.log(result.length ? "sale true" : "sale false")
            if (result.length) {
                Object.assign(picking, result[0])
                setPickingDetail([...pickingDetail])
            } else {
                const rowTable = document.querySelector(`tr[data-row-key="${picking.pickingID}"] > td`)
                if (rowTable) {
                    rowTable.style.background = 'red'
                    rowTable.style.color = 'white'
                    rowTable.style['font-weight'] = 'bold'
                }
            }
        }
    }

    useEffect(() => {
        const filteredPickingDetail = pickingDetail.filter(({ articleStatus }) => articleStatus === '1')
        getDetailSAP(filteredPickingDetail) 
    }, [])

    const [selectedBarCodes, setSelectedBarcodes] = useState({})

    const height = 33
    const leftBlockMargin = 10
    const rightBlockMargin = 115
    const pdfDoc = new jsPDF('p', 'mm', [297, 210])
    pdfDoc.setLineWidth(.1)
    pdfDoc.setFont("times", "normal")
    pdfDoc.line(105, 0, 105, 297)
    pdfDoc.setFontSize(8)

    for (let index = 0; index < 10; index++) {
        pdfDoc.line(0, height * index, 210, height * index)
    }

    const textToBase64Barcode = (text) => {
        const canvas = document.createElement("canvas")
        jsBarcode(canvas, text)
        return canvas.toDataURL("image/png")
    }


    useEffect(() => {
        if (pickingDetail.length === 0) history.goBack()
    }, [pickingDetail])

console.log('selectedBarCodes', selectedBarCodes)

    const printBarcodesHandler = async () => {
        let numberOfTotalCodes = 1
        let numberOfLinesInPage = 1
        let marginTop = 0
        const pageHeight = 297
        Object.keys(selectedBarCodes).forEach(code => {
            for (let index = 1; index <= selectedBarCodes[code]; index++) {
                const canvasString = textToBase64Barcode(code)
                pdfDoc.addImage(canvasString, "JPEG", numberOfTotalCodes % 2 ? leftBlockMargin : rightBlockMargin, 2 + marginTop, 40, 20)
                const barCodeDetail = pickingDetail.filter(({ articleBarCode }) => articleBarCode === code)
                pdfDoc.text(`Articulo: ${barCodeDetail[0]?.ItemName.toString().substring(0, 35)}`, numberOfTotalCodes % 2 ? leftBlockMargin : rightBlockMargin, 25 + marginTop)
                pdfDoc.text(`COD: ${barCodeDetail[0]?.ItemCode}`, numberOfTotalCodes % 2 ? leftBlockMargin : rightBlockMargin, 30 + marginTop)
                // Before adding new content
                if (marginTop === pageHeight) {
                    pdfDoc.addPage()
                    marginTop = 0
                    numberOfLinesInPage = 1
                    for (let newIndex = 0; newIndex < 10; newIndex++) pdfDoc.line(0, height * newIndex, 210, height * newIndex)
                    pdfDoc.line(105, 0, 105, 297)
                    const canvasString = textToBase64Barcode(code)
                    pdfDoc.addImage(canvasString, "JPEG", numberOfTotalCodes % 2 ? leftBlockMargin : rightBlockMargin, 2 + marginTop, 40, 20)
                    const barCodeDetail = pickingDetail.filter(({ articleBarCode }) => articleBarCode === code)
                    pdfDoc.text(`Articulo: ${barCodeDetail[0]?.ItemName.toString().substring(0, 35)}`, numberOfTotalCodes % 2 ? leftBlockMargin : rightBlockMargin, 25 + marginTop)
                    pdfDoc.text(`COD: ${barCodeDetail[0]?.ItemCode}`, numberOfTotalCodes % 2 ? leftBlockMargin : rightBlockMargin, 30 + marginTop)
                }
                numberOfTotalCodes++
                numberOfLinesInPage += numberOfTotalCodes % 2
                if (numberOfLinesInPage > 1) marginTop = 33 * (numberOfLinesInPage - 1)
            }
        })
        pdfDoc.output('dataurlnewwindow')
    }

    return (
        <div className="rounded p-2 p-md-5 position-realative" id="tab-container">
            <Form id="div-canvas" as={Row}>
                {pickingDetail.map(picking => {
                    return (
                        <Col className="mx-1">
                            <Form.Group controlId="formBasicCheckbox" className="mr-3">
                                <Form.Check 
                                    type="checkbox"
                                    label={"Art: " + picking.articleBarCode}
                                    name={picking.articleBarCode}
                                    onChange={e => setSelectedBarcodes({ ...selectedBarCodes, [e.target.name]: e.target.checked })}
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control
                                    className="form-control-sm" type="text"
                                    placeholder="Cantidad a Imprimir"
                                    disabled={!selectedBarCodes?.[picking.articleBarCode]}
                                    onChange={e => setSelectedBarcodes({ ...selectedBarCodes, [picking.articleBarCode]: Number(e.target.value) || true })}
                           

                                 
                                />
                            </Form.Group>
                        </Col>
                    )
                })

                }
            </Form>
            {
                Object.keys(selectedBarCodes).some(code => {
                    return (selectedBarCodes[code] === true || selectedBarCodes[code] > 0)
                }) && <Button variant="primary" onClick={printBarcodesHandler}>Imprimir</Button>
            }
        </div>
    )
}

export default TabEtiquetas