import React from 'react'
import { Toast } from 'react-bootstrap'


function ToastComponent({ message, title, show, setShow, color = "bg-primary", reference, delay = 4000 }) {

    return (
        <Toast onClose={() => setShow()} ref={reference} show={show} delay={delay} autohide style={{
            position: 'absolute',
            top: 10,
            right: 10,
            backgroundColor: 'whitesmoke'
        }}
        >
            <Toast.Header closeButton={false} className={`${color} text-white`}>
                {title}
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    )
}

export default ToastComponent