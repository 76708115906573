import { useContext, useEffect, useRef, useState } from 'react'
import { Form, Col, Row, Button, Spinner, Container, Alert } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import globalContext from '../globalcontext/globalContext'
import { loginHandler } from '../services/loginHandler'
import ModalComponent from './ModalComponent'

function LoginForm() {

    const { setIslogedInHandler, userData = false } = useContext(globalContext)
    const [warehouses, setWarehouses] = useState([])
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [errorLogin, setErrorLogin] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies()

    const userNameRef = useRef(null)
    const userPasswordRef = useRef(null)

    const history = useHistory()

    const [loading, setLoading] = useState(false)

    const login = async (e) => {
        e.preventDefault()
        setLoading(true)
        const { status, result = {}, error_msg = "" } = await loginHandler({
            userName: userNameRef?.current?.value,
            password: userPasswordRef?.current?.value
        })
        if (result?.BPLIDUser?.length > 0) {
            setCookie("B1SESSION", result.B1SESSION.split("=")[1])
            setCookie("ROUTEID", result.ROUTEID.split("=")[1])
            setCookie("userName", userNameRef?.current?.value)
            setCookie('roleUser', result?.roleUser)
            setWarehouses(result?.BPLIDUser)
            setLoading(false)
        }
        if (error_msg.includes("Credentials are Incorrects")) {
            setLoading(false)
            setErrorLogin(true)
        }
    }

    useEffect(() => {
        if (userData) {
            if (!!cookies.BPLName && !!cookies.ROUTEID && !!cookies.B1SESSION && history.location.pathname !== '/picking') {
                history.replace('/picking')
            }
        } else {
            Object.entries(cookies).forEach(([cookieName]) => removeCookie(cookieName))
            if (!!cookies.BPLName === false && !!cookies.ROUTEID === false && !!cookies.B1SESSION === false && history.location.pathname !== '/') {
                history.replace('/')
            }
        }
    }, [])

    return (
        <Container>
            <Row style={{ minHeight: '100vh' }} className="mx-0 justify-content-center align-items-center">
                <Col xs="12" md="5">
                    <h3 className="text-center">Sistema de Picking 2.0</h3>
                    <Form onSubmit={login} className="rounded shadow-lg" style={{ background: "whitesmoke", paddingTop: "20px" }}>
                        <Col>
                            <Form.Control className="mt-3 form-control-sm" ref={userNameRef} autoFocus placeholder="Usuario" name="userName" />
                            <Row className="justify-content-end mx-0">
                                <Form.Control className="mt-3 form-control-sm" type={isPasswordVisible ? "text" : "password"} ref={userPasswordRef} placeholder="Contraseña" name="password" style={{ position: "relative" }} />
                                <i role="button" className={`far fa-eye${isPasswordVisible ? "-slash" : ""}`} style={{ position: "absolute", padding: "25px 13px" }} onClick={() => setIsPasswordVisible(!isPasswordVisible)}></i>
                            </Row>
                            {
                                errorLogin ? <Alert variant="danger" className="mt-3" onClose={() => setErrorLogin(false)} dismissible>
                                    <p className="mb-0">
                                        Credenciales Invalidas
                                    </p>
                                </Alert>
                                    :
                                    null
                            }
                        </Col>
                        <Row className="justify-content-end border-top mt-4 pr-3">
                            {
                                !loading ?
                                    <Button variant="primary" className="m-2" type="submit" onClick={(e) => {
                                        setLoading(true)
                                        login(e)
                                    }}>
                                        Ingresar
                                    </Button>
                                    :
                                    <Button variant="primary" className="m-2 btn-sm" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        Ingresando...
                                    </Button>
                            }
                        </Row>
                    </Form>
                </Col>
            </Row>
            <ModalComponent
                modalTitle="Elegír sucursal"
                modalIsVisible={warehouses.length > 0}
                showCancelButton={false}
                handleClose={() => {
                    setWarehouses([])
                    setIslogedInHandler(!!cookies.BPLName && !!cookies.B1SESSION && !!cookies.ROUTEID )
                    history.replace("/picking")
                }}
            >
                {warehouses.map(bpl => <Row key={bpl?.BPLName} className="justify-content-between mx-0 border my-1">
                    <span className="col-5">Sucursal: {bpl?.AliasName}</span>
                    <span>N° Sucursal: {bpl?.BPLName}</span>
                    <Form.Check
                        inline
                        type="radio"
                        name={bpl?.BPLName}
                        value={bpl?.BPLName}
                        checked={cookies.BPLName == bpl?.BPLName}
                        onChange={() => {
                            console.log("BPLName", bpl?.BPLName, cookies.BPLName)
                            setCookie("BPLName", bpl?.BPLName)
                            setCookie("AliasName", bpl?.AliasName)
                            setCookie("BPLID", bpl?.BPLID)
                        }}
                    />
                </Row>)}
            </ModalComponent>
        </Container>
    )
}

export default LoginForm