import Notiflix from 'notiflix'
import { useContext, useEffect } from 'react'
import { Form, Row } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import PickingContext from '../../context/pickingContext'
import { getItemService } from '../pickingTab/services/getItemService'
import DestinyWarehouses from './DestinyWarehouses'
import OriginWarehouses from './OriginWarehouses'
import { checkStock } from './services/checkStock'
import { getPointOfIssueCodes } from './services/getPointOfIssueCodes'

function FormWarehouse() {

    const [cookies] = useCookies()

    const { pointsOfIssueCodes = [], selectedOriginWarehouse = '', pickingDetail = [], setPickingDetail, selectedPointOfIssueCode, setPointsOfIssueCode, setSelectedPointsOfIssueCode } = useContext(PickingContext)

    const activePickings = pickingDetail.filter(picking => picking?.articleStatus === '1' && !!picking?.ItemCode)

   
    

    const checkStockHandler = async (activePickings) => {
        Notiflix.Block.dots("#tab-container", "Cargando stock de productos...")
        if (!!selectedOriginWarehouse) {
            let newOkPickings = []
            for (const [index, item] of activePickings.entries()) {
                const { result = [], message = '' } = await checkStock({ ItemCode: item?.ItemCode, WareHouse: selectedOriginWarehouse })
                if (result.length > 0) {
                    const { InStockQuantity } = result[0]
                    newOkPickings = [...newOkPickings, { ...activePickings[index], InStockQuantity }]
                }
            }
            setPickingDetail(newOkPickings)
        }
        Notiflix.Block.remove("#tab-container")
    }

    const getItemDetailHandler = async () => {
        Notiflix.Block.dots("#tab-container", "Cargando detalle de productos...")
        if (activePickings.length > 0) {
            let newOkPickings = []
            for (const [index, item] of activePickings.entries()) {
                if (!!item?.ItemCode === false) {
                    const { result = [], message = '' } = await getItemService({ barCode: item?.articleBarCode })
                    if (result.length > 0) {
                        const { ItemCode, ItemName, U_Division } = result[0]
                        newOkPickings = [...newOkPickings, { ...activePickings[index], ItemCode, ItemName, U_Division }]
                    }
                }
            }
            setPickingDetail(newOkPickings)
        }
        Notiflix.Block.remove("#tab-container")
    }

    const getPointOfIssueCodesHandler = async () => {
        if (pointsOfIssueCodes.length === 0) {
            const { result = [], message = '', error_msg = '' } = await getPointOfIssueCodes({ BPLID: cookies?.BPLID })
            if (message.includes('Successfully')) setPointsOfIssueCode(result)
        }
    }

    useEffect(() => {
        getItemDetailHandler()
        getPointOfIssueCodesHandler()
    }, [])

    useEffect(() => {
        checkStockHandler(activePickings)
    }, [selectedOriginWarehouse])

  

    return (
        <Row className="justify-content-between mx-0 mt-3 mt-md-0">
            <div className="col-md-6">
                {
                    pointsOfIssueCodes?.length === 1?
                    <Form.Group>
                        <Form.Label className="mb-0">Punto de Emisión</Form.Label>
                        <Form.Control as="select" className="form-control-sm"
                            onChange={e => setSelectedPointsOfIssueCode(e.target.value)}
                            value={selectedPointOfIssueCode}
                        >
                                                        <option hidden>Elegir Punto de Emisión</option>

                            {
                                pointsOfIssueCodes?.map(point => <option
                                    key={point?.Code}
                                    value={point?.Code}
                                    title={point?.Desc}
                                >
                                    {point?.Code}-{point?.Desc}
                                </option>)
                            }
                        </Form.Control>
                     
                    </Form.Group>: <Form.Group>
                        <Form.Label className="mb-0">Punto de Emisión</Form.Label>
                        <Form.Control as="select" className="form-control-sm"
                            onChange={e => setSelectedPointsOfIssueCode(e.target.value)}
                            value={selectedPointOfIssueCode}
                        >
                            <option hidden>Elegir Punto de Emisión</option>
                            {
                                pointsOfIssueCodes?.map(point => <option
                                    key={point?.Code}
                                    value={point?.Code}
                                    title={point?.Desc}
                                >
                                    {point?.Code}-{point?.Desc}
                                </option>)
                            }
                        </Form.Control>
                     
                    </Form.Group>}
            </div>
            <Row className="mx-0">
                <OriginWarehouses />
                <DestinyWarehouses />
            </Row>
        </Row>
    )
}

export default FormWarehouse