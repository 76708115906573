
import { getPickingDetails } from './services/getPickingDetails'
import { useHistory } from 'react-router-dom'
import PickingContext from '../../context/pickingContext'
import { useContext, useState } from 'react'


function ActionsForTableConsultExit({ rowData }) {

    const history = useHistory()

    const { setPickingEditable, setSelectedPickingEditable } = useContext(PickingContext)

    const getPickingDetailsHandler = async (code) => {
        const { result = [] } = await getPickingDetails({ pickingCode: code })
        console.log('result',result);
        const   newResult= result.reduce((acc, el) => ({
            ...acc,
            [el.articleBarCode]: {quantity:el.articleQuantity} 
        }),{})

        setPickingEditable(newResult)
        setSelectedPickingEditable(rowData)
         console.log(newResult)
         history.push(`/editar/${code}`)
    }

    return (
        <div className="row flex-nowrap mx-0 align-items-center justify-content-center">
            
                <div>
                    <i className="fas fa-edit text-primary pt-2 mx-1"
                        title="Editar"
                        style={{ fontSize: '20px' }}
                        role="button"
                        onClick={() => getPickingDetailsHandler(rowData.pickingCode)}></i>
                </div>

        </div>
    )
}

export default ActionsForTableConsultExit




