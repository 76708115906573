import { useContext } from "react"
import { editPickingDetails } from '../consultTab/services/editPicking'
import { Button, Row } from 'react-bootstrap'
import Notiflix from 'notiflix'
import PickingContext from "../../context/pickingContext"
import postOrder from './services/postOrder'
import Swal from 'sweetalert2'


function ButtonSavePickingOrder() {

    const { pickingDetail = [], formatedEntryItems = {}, linesForDocumentLines = {}, selectedPicking = {}, setSelectedPicking, setPendingOrders, setInventoryEntry, setPickingDetail, setSupplierData, clearLinesForDocumentLines } = useContext(PickingContext)

    return (
        <Row className="justify-content-end align-items-end mx-0">

            {
                pickingDetail.every(({ ItemCode }) => Object.keys(linesForDocumentLines).map(code => code.slice((code.lastIndexOf("-") + 1), code.length)).includes(ItemCode))
                &&
                <Button
                    variant="primary"
                    onClick={async () => {
                        Notiflix.Block.dots('#tab-container', 'Ingresando Mercancia...')
                        const formatedDateForSAP = formatedEntryItems?.TaxDate?.split("/")
                        console.log('formatedDateForSAP', formatedDateForSAP);

                        const { result = [], status,error_msg } = await postOrder({ ...formatedEntryItems, TaxDate: `${formatedDateForSAP[2]}-${formatedDateForSAP[1]}-${formatedDateForSAP[0]}` })
                        if (status === 201) {
                            if (result.length > 0) {
                                const { message = "" } = await editPickingDetails({ pickingCode: selectedPicking?.pickingCode, entrySAP: result[0]?.DocEntry ,DocNum: result[0]?.DocNum })
                                if (message.includes('Successfully')) {
                                    setSelectedPicking({})
                                    setPendingOrders([])
                                    setInventoryEntry({})
                                    setPickingDetail([])
                                    setSupplierData({})
                                    clearLinesForDocumentLines()
                                    Swal.fire({
                                        icon: "success",
                                        title: "Ingreso exitoso",
                                        timer: 2500
                                    })
                                }
                            }
                            Notiflix.Block.remove('#tab-container')
                        } else {
                            Notiflix.Block.remove('#tab-container')
                            Swal.fire({ icon: 'error', title: 'Ocurrió un error', text:error_msg })
                        }
                    }}
                >
                    Guardar
                </Button>
            }
        </Row>
    )
}

export default ButtonSavePickingOrder