import { useContext, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import PickingContext from '../../context/pickingContext'
import { getOriginWarehouse } from './services/getOriginWarehouse'

function OriginWarehouses() {

    const [cookies] = useCookies()
    const { selectedPicking = {}, originWarehouse = [], setOriginWarehouse, selectedOriginWarehouse = '', setSelectedOriginWarehouse } = useContext(PickingContext)

    const getOriginWarehouseHandler = async () => {
        const { result = [], message = '' } = await getOriginWarehouse({ BPLID: cookies?.BPLID })
        if (result.length > 0) setOriginWarehouse(result)
    }

 

    useEffect(() => {
        selectedPicking?.pickingType === "Egreso" && getOriginWarehouseHandler() && setSelectedOriginWarehouse(originWarehouse)
    }, [])


    return (
        <Col md={6}>
            <Form.Group controlId="warehoseOrigin">
                <Form.Label className="mb-0">Almacen Origen</Form.Label>
                <Form.Control as="select" className="form-control-sm"
                    onChange={(e) => setSelectedOriginWarehouse(e.target.value)}
                    value={selectedOriginWarehouse}
                >
                    <option hidden>Elegir Almacen</option>
                    {
                        originWarehouse?.map(warehouse => <option
                            key={warehouse?.WarehouseCode}
                            value={warehouse?.WarehouseCode}
                            title={warehouse?.WarehouseName}
                        >
                            {warehouse?.WarehouseCode}-{warehouse?.WarehouseName.substring(0, 20)}{(warehouse?.WarehouseName.length > 20) ? '...' : ''}
                        </option>)
                    }
                </Form.Control>
            </Form.Group>
        </Col>
    )
}

export default OriginWarehouses