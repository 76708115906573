import { useCookies } from "react-cookie"
import { Route, Redirect } from 'react-router-dom'

export default function PrivateRoute({ component: Component, role, ...rest }) {

    const [cookies] = useCookies()
    const roleLocal = cookies?.roleUser

    return (
        <Route {...rest}
            render={props => (
                !!roleLocal == false ?
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />
                    :
                    (!!cookies.BPLName && !!cookies.B1SESSION && !!cookies.ROUTEID && role == roleLocal)
                        ? <Component {...props} />
                        :
                        roleLocal == "1" ?
                            <Redirect
                                to={{
                                    pathname: '/picking',
                                    state: { from: props.location }
                                }}
                            />
                            :
                            <Redirect
                                to={{
                                    pathname: '/consultas',
                                    state: { from: props.location }
                                }}
                            />
            )}
        />
    )
}