import { useContext } from 'react'
import PickingContext from '../../context/pickingContext'
import Notiflix from 'notiflix'
import { useCookies } from 'react-cookie'
import { getPendingOrders } from './services/getPendingOrders'
import { Row } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { disablePickingLine } from './services/disablePickingLine'

function ActionsForPickingsForValidation2({ rowData, setCurrentPicking, setCurrentPickingLine }) {

    const [cookies] = useCookies()

    const { pickingDetail = [], formatedEntryItems = {}, selectedPicking = {}, supplierData = {}, linesForDocumentLines = {}, setPendingOrders,setInventoryEntry, setPickingDetail } = useContext(PickingContext)


    const disablePickingLineHandler = async (id) => {
        Swal.fire({
            icon: 'warning',
            title: 'Estás por borrar una línea',
            text: '¿Estas seguro/a?',
            showCancelButton: true
        }).then(async ({ isConfirmed }) => {
            if (isConfirmed) {
                const { message = '' } = await disablePickingLine({ id, articleStatus: '0' })
                if (message?.includes('Successfully')) {
                    pickingDetail.forEach(article => {
                        if (article.pickingID === id) article.articleStatus = '0'
                    })
                    setPickingDetail([...pickingDetail])
                    Swal.fire('Se borró la línea con éxito', '', 'success')
                }
            }
        })
    }

    return (
        <Row className="justify-content-around align-items-center mx-0">
            {
                selectedPicking?.pickingType !== "Egreso" ?
                    <span
                        role="button"
                        onClick={async () => {
                            Notiflix.Block.dots('.container-validation', 'Cargando Ordenes...')
                            const { result = [] } = await getPendingOrders({ ItemCode: rowData.ItemCode?.trim(), CardCode: supplierData?.CardCode?.trim(), BPLName: cookies.BPLName })
                            if (result?.length > 0) {setPendingOrders(result)                             }
                            else Swal.fire({ icon: 'info', title: 'No se encontró el articulo', text: 'No existen facturas ni ordenes para este articulo.' })
                            setCurrentPicking(rowData)
                            setInventoryEntry({ ...formatedEntryItems, U_NroPicking: rowData.pickingCode })
                            Notiflix.Block.remove('.container-validation')
                        }}
                    >
                        {
                        Object.keys(linesForDocumentLines).filter(accesKey => accesKey.includes(rowData.ItemCode)).length > 0   ? "Ver Pedido" : "Asignar Pedido"
                         } 
                        <i className="far fa-file-alt text-info ml-2"
                            title="Seleccionar N° Pedido o Fact. Reserva"
                            style={{ fontSize: "17px" }}
                        ></i>
                    </span>
                    :
                    <Row className="mx-0">
                        <i role="button"
                            className="far fa-edit text-primary ml-2"
                            title="Editar línea"
                            style={{ fontSize: "17px" }}
                            onClick={() => setCurrentPickingLine(rowData)}
                        ></i>

                        <i role="button"
                            className="far fa-trash-alt text-danger ml-2"
                            title="Borrar línea"
                            style={{ fontSize: "17px" }}
                            onClick={() => disablePickingLineHandler(rowData?.pickingID)}
                        ></i>
                    </Row>
            }
        </Row>

    )
}

export default ActionsForPickingsForValidation2