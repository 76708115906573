export const cancelPickingHandler = async (canceledPicking) => {

    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultCancelPicking = await window.fetch(`${requestPath}/back/routes/sqlPicking.php`, {
        method: 'DELETE',
        credentials: 'include',
        body: JSON.stringify(canceledPicking)
    })

    const { result = [], message = "", error_msg = "", error_catch = "" } = await resultCancelPicking.json()
    return { result, message, error_catch, error_msg }
}