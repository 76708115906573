import React, { useReducer } from 'react'
import { actionsPicking } from './actionsPicking'
import PickingContext from './pickingContext'
import { pickingReducer } from './pickingReducer'

function PickingServicesContext({ children }) {

    const initialPickingValues = {
        picking: {},
        pickingEditable:[],
        selectedPickingEditable:{},
        excel: [],
        searchType: 'cod',
        pickingOutput: [],
        pickingForValidation: [],
        pickingFound: [],
        pickingDetail: [],
        supplierData: {},
        pendingOrders: [],
        linesForDocumentLines: {},
        formatedEntryItems: {},
        cancelPicking: {},
        selectedPicking: {},
        pickingType: '',
        originWarehouse: [],
        destinyWarehose: [],
        selectedOriginWarehouse: '',
        selectedDestinyWarehouse: {},
        pointsOfIssueCodes: [],
        selectedPointOfIssueCode: ''
    }


    const [state, dispatch] = useReducer(pickingReducer, initialPickingValues)

    const { picking,pickingEditable,selectedPickingEditable, excel, searchType, pickingOutput, pickingForValidation, pickingFound, pickingDetail, supplierData, pendingOrders, linesForDocumentLines, formatedEntryItems, cancelPicking, selectedPicking, pickingType, originWarehouse, destinyWarehose, selectedOriginWarehouse, selectedDestinyWarehouse, pointsOfIssueCodes, selectedPointOfIssueCode } = state

    const addPickingHandler = ({ picking }) => {
        dispatch({
            type: actionsPicking.ADD_PICKING,
            payload: picking
        })
    }

    const setSearchType = (searchType) => {
        dispatch({
            type: actionsPicking.TYPE_SEARCH,
            payload: searchType
        })
    }

    const setPickingFound = (pickingsFound) => {
        dispatch({
            type: actionsPicking.PICKINGFOUND,
            payload: pickingsFound
        })
    }

    const setPickingDetail = (pickingDetails) => {
        dispatch({
            type: actionsPicking.PICKINGDETAIL,
            payload: pickingDetails
        })
    }

    const removeCurrentPickingHandler = (currentPicking) => {
        delete picking[currentPicking]
        if (!!currentPicking) {
            dispatch({
                type: actionsPicking.REMOVE_PICKING,
                payload: { ...picking }
            })
        } else {
            dispatch({
                type: actionsPicking.REMOVE_PICKING,
                payload: {}
            })
        }
    }

    const removeCurrentPickingHandlerEdit = (currentPicking) => {
        delete pickingEditable[currentPicking]
        if (!!currentPicking) {
            dispatch({
                type: actionsPicking.REMOVE_PICKING,
                payload: { ...pickingEditable }
            })
        } else {
            dispatch({
                type: actionsPicking.REMOVE_PICKING,
                payload: {}
            })
        }
    }

    const processInputPicking = async ({ pickingValue, pickingQuantity }) => {
        var quantity = Number(pickingQuantity) > 0 ? Number(pickingQuantity) : !!picking?.[pickingValue]?.quantity != false ? (picking?.[pickingValue]?.quantity + 1) : 1
        dispatch({
            type: actionsPicking.ADD_PICKING,
            payload: { pickingValue, pickingQuantity: quantity }
        })
    }

    const processInputPickingEditable = async ({ pickingValueEdit, pickingQuantityEdit}) => {
        var quantity = Number(pickingQuantityEdit) > 0 ? Number(pickingQuantityEdit) : !!pickingEditable?.[pickingValueEdit]?.quantity != false ? (pickingEditable?.[pickingValueEdit]?.quantity + 1) : 1
        dispatch({
            type: actionsPicking.ADD_PICKING_EDIT,
            payload: { pickingValueEdit, pickingQuantityEdit: quantity }
        })
    }

    

    

    const confirmPickingToOutput = async ({ pickingTrust }) => {
        dispatch({
            type: actionsPicking.CONFIRM_PICKING,
            payload: pickingTrust
        })
    }

    const confirmToValidatePicking = async (pickingsCheckedForValidation) => {
        dispatch({
            type: actionsPicking.FOR_VALIDATION_PICKING,
            payload: pickingsCheckedForValidation
        })
    }

    const setSupplierData = async (supplierData) => {
        dispatch({
            type: actionsPicking.SET_SUPPLIERDATA,
            payload: supplierData
        })
    }

    const setPendingOrders = async (pendingOrders) => {
        dispatch({
            type: actionsPicking.SET_PENDINGORDERS,
            payload: pendingOrders
        })
    }

    const setLinesForDocumentLines = (line) => {
        dispatch({
            type: actionsPicking.SET_LINESFORDOCUMENTLINES,
            payload: line
        })
    }

    const clearLinesForDocumentLines = () => {
        dispatch({
            type: actionsPicking.CLEAR_LINESFORDOCUMENTLINES,
            payload: {}
        })
    }

    const setInventoryEntry = (line) => {
        dispatch({
            type: actionsPicking.SET_FORMATEDENTRYITEMS,
            payload: line
        })
    }

    const setCancelPicking = (pickingCancelData) => {
        dispatch({
            type: actionsPicking.SET_CANCELPICKINGDATA,
            payload: pickingCancelData
        })
    }

    const setSelectedPicking = (pickingSelected) => {
        dispatch({
            type: actionsPicking.SET_PICKINGSELECTED,
            payload: pickingSelected
        })
    }

    const setPickingType = (pickingType = "Ingreso") => {
        dispatch({
            type: actionsPicking.TYPE_PICKING,
            payload: pickingType
        })
    }

    const setOriginWarehouse = warehouses => {
        dispatch({
            type: actionsPicking.SET_ORIGINWAREHOUSES,
            payload: warehouses
        })
    }

    const setDestinyWarehouse = warehouses => {
        dispatch({
            type: actionsPicking.SET_DESTINYWAREHOUSES,
            payload: warehouses
        })
    }

    const setSelectedOriginWarehouse = warehouse => {
        dispatch({
            type: actionsPicking.SET_SELECTEDORIGINWAREHOUSE,
            payload: warehouse
        })
    }

    const setSelectedDestinyWarehouse = warehouse => {
        dispatch({
            type: actionsPicking.SET_SELECTEDDESTINYWAREHOUSE,
            payload: warehouse
        })
    }

    const setPointsOfIssueCode = pointOfIssueCodes => {
        dispatch({
            type: actionsPicking.SET_POINTOFISSUECODE,
            payload: pointOfIssueCodes
        })
    }

    const setSelectedPointsOfIssueCode = pointOfIssueCode => {
        dispatch({
            type: actionsPicking.SET_SELECTEDPOINTOFISSUECODE,
            payload: pointOfIssueCode
        })
    }

    const setPickingEditable = (pickingEditable) => {
dispatch({
    type:actionsPicking.EDITABLE_PICKING,
    payload: pickingEditable
})
    }


    const setSelectedPickingEditable = selectedPickingEditable => {
        dispatch({
            type:actionsPicking.SET_SELECTED_PICKING,
            payload:selectedPickingEditable
        })
    }

    return (
        <PickingContext.Provider value={{
            picking,
            pickingEditable,
            selectedPickingEditable,
            excel,
            searchType,
            pickingType,
            supplierData,
            pickingFound,
            pendingOrders,
            pickingDetail,
            pickingOutput,
            cancelPicking,
            selectedPicking,
            originWarehouse,
            destinyWarehose,
            formatedEntryItems,
            pickingForValidation,
            linesForDocumentLines,
            pointsOfIssueCodes,
            selectedPointOfIssueCode,
            selectedOriginWarehouse,
            selectedDestinyWarehouse,
            setSearchType,
            setPickingEditable,
            setPickingType,
            setSupplierData,
            setPickingFound,
            setPendingOrders,
            setPickingDetail,
            setCancelPicking,
            addPickingHandler,
            setInventoryEntry,
            setSelectedPicking,
            setSelectedPickingEditable,
            setOriginWarehouse,
            setDestinyWarehouse,
            processInputPicking,
            processInputPickingEditable,
            confirmPickingToOutput,
            confirmToValidatePicking,
            setLinesForDocumentLines,
            clearLinesForDocumentLines,
            setSelectedOriginWarehouse,
            setSelectedDestinyWarehouse,
            removeCurrentPickingHandler,
            removeCurrentPickingHandlerEdit,
            setPointsOfIssueCode,
            setSelectedPointsOfIssueCode
        }}>
            {children}
        </PickingContext.Provider>
    )
}

export default PickingServicesContext