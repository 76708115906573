export const postPickingTransfer_exit = async (transfer) => {

    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultPostPickingTransfer = await window.fetch(`${requestPath}/back/routes/order_010.php`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ transfer })
    })

    const { result, message, error_msg } = await resultPostPickingTransfer.json()
    return { result, message, error_msg }
}
