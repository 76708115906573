import { Divider } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PickingContext from '../../context/pickingContext'
import TableOfPickingForValidation from './TableOfPickingForValidation'
import FormFormatedPicking from './FormFormatedPicking'
import ModalSupplier from './ModalSupplier'
import ButtonSavePickingEntry from './ButtonSavePickingEntry'
import FormWarehouse from './FormWarehouse'

function ValidationTab() {

    const history = useHistory()
    const { pickingDetail = [], supplierData = {}, selectedPicking = {}, setSupplierData, setSelectedDestinyWarehouse, setSelectedOriginWarehouse, clearLinesForDocumentLines } = useContext(PickingContext)

    const [showModal, setShowModal] = useState(false)

    const setModalIsVisibleHandler = (bool = false) => {
        setShowModal(bool)
    }

    useEffect(() => {
        if (pickingDetail.length === 0) history.goBack()
    }, [pickingDetail])

    useEffect(() => {
        setSupplierData({})
        // setPickingDetail([])
        setSelectedDestinyWarehouse({})
        setSelectedOriginWarehouse('')
        clearLinesForDocumentLines({})
    }, [])

    return (
        <Container className="rounded p-2 p-md-5 container-validation" id="tab-container">
            {
                Object.keys(supplierData).length > 0 && <FormFormatedPicking />
            }
            {
                selectedPicking?.pickingType === "Egreso" && <FormWarehouse />
            }
            <Divider />
            <TableOfPickingForValidation setModalIsVisibleHandler={setModalIsVisibleHandler} />
            {
                selectedPicking?.pickingType !== "Egreso" &&
                <ModalSupplier
                    setModalIsVisibleHandler={setModalIsVisibleHandler}
                    showModal={showModal}
                />
            }
            <ButtonSavePickingEntry />
            {selectedPicking?.pickingType === "Egreso" && <ButtonSavePickingEntry />}
        </Container>
    )
}

export default ValidationTab