export const editPickingDetails = async ({ pickingCode, entrySAP, DocNum }) => {

    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultEditPinkingDetail = await window.fetch(`${requestPath}/back/routes/sqlPicking.php?`, {
        method: 'PUT',
        body: JSON.stringify({ pickingCode, entrySAP,DocNum })
    })
        .catch(err => {
            console.log(err)
        })
    const { result = [], message = "", error_msg = "" } = await resultEditPinkingDetail.json()
    return { result, message, error_msg }
}