import { useContext, useEffect } from 'react'
import { Row, Form, Container } from 'react-bootstrap'
import pickingContext from '../../context/pickingContext'
import { useCookies } from 'react-cookie'

const SwitchPickingType = () => {

    const { pickingType = "", setPickingType } = useContext(pickingContext)
    const [cookies] = useCookies()

    useEffect(() => {
        if (cookies?.BPLName !=='010') setPickingType("Egreso")
    }, [])
    
    return (
        <>
     {cookies?.BPLName =='010' ?
           <Row className="justify-content-end mx-0 align-items-center mb-4">
            <p className="justify-content-end mx-0 align-items-center mb-0 mr-3" style={{backgroundColor: '#007bff', color:'#FFF', padding:'0.5em 1em 0.5em 1em',borderRadius:'.25rem' }} ><b>TIPO DE PICKING: </b></p> 
                <Form.Check 
                type="checkbox"
                label="Ingreso"
                checked={pickingType === "Ingreso"}
                onChange={(e) => setPickingType("Ingreso")}
                />
                &nbsp;
                &nbsp;
                   <Form.Check 
                type="checkbox"
                label="Egreso"
                checked={pickingType === "Egreso"}
                onChange={(e) => setPickingType("Egreso")}
                />
            </Row>: <Row className="justify-content-end mx-0 align-items-center mb-4">
            <p className="justify-content-end mx-0 align-items-center mb-0 mr-3" style={{backgroundColor: '#007bff', color:'#FFF', padding:'0.5em 1em 0.5em 1em',borderRadius:'.25rem' }} ><b>TIPO DE PICKING: </b></p> 
            
                   <Form.Check 
                type="checkbox"
                label="Egreso"
                checked={pickingType === "Egreso"}
                onChange={(e) => setPickingType("Egreso")}
                />
            </Row>


     }



        </>

    )
}

export default SwitchPickingType