export const savePickingEdit = async ({ pickingEditable, pickingCode, pickingType='' }) => {
    const pickingFormated = Object.keys(pickingEditable).map(accesKey => ({ articleBarCode: accesKey, articleQuantity: pickingEditable[accesKey]?.quantity, articlePending: pickingEditable[accesKey]?.quantity }))
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultSaveItemsEdit = await window.fetch(`${requestPath}/back/routes/sqlPicking.php`, {
        method: "PUT",
        body: JSON.stringify({ lines: pickingFormated, pickingCode:pickingCode, pickingType}),
        credentials: 'include'
    })

    const { result, message, error_catch } = await resultSaveItemsEdit.json()

    return { result, message, error_catch }
}