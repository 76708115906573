import ModalComponent from "../ModalComponent"
import { Row,Nav } from 'react-bootstrap'
import { Divider } from "antd"


function ModalShowSavedicking({ savedPickingDetail, setSavedPickingDetail }) {




    return (
        <ModalComponent
            modalIsVisible={Object.keys(savedPickingDetail).length > 0}
            handleClose={() => setSavedPickingDetail({})}
        >
        
            <Row className="justify-content-between mx-0">
                <p className="mb-0">Proveedor: {savedPickingDetail?.CardName}</p>
                {
                   Object.values(savedPickingDetail)[6]!== 'Egreso' ?
                <p className="mb-0">Fecha Ingreso: {savedPickingDetail?.CreationDate}</p>:
                <p className="mb-0">Fecha Egreso : {savedPickingDetail?.CreationDate}</p>

                }
            </Row>
            <Divider />
            {Object.values(savedPickingDetail)[6]!== 'Egreso' ?
                        <h6 className="mb-0">Ingresos</h6>:
                        <h6 className="mb-0">Egresos</h6>


            }
            <div className="m-3">
                {
                    savedPickingDetail?.DocumentLines?.length > 0 &&
                    savedPickingDetail?.DocumentLines?.map(p => <Row className="rounded border px-3 justify-content-between">
                        <p className="mb-0 col-3">Cod. Prod: {p?.ItemCode}</p>
                        <p className="text-truncate mb-0 col-6">Nombre Prod: {p?.ItemDescription}</p>
                        
                        {
                            Object.values(savedPickingDetail)[6]!== 'Egreso' ?
     <p className="text-truncate mb-0 col-3">Cant. Ingresada: {p?.Quantity}</p>:
     <p className="text-truncate mb-0 col-3">Cant. Egresada: {p?.Quantity}</p>

                        }
                
                    </Row>)
                }
            </div>
        </ModalComponent>
    )
}

export default ModalShowSavedicking