export const checkStock = async ({ ItemCode, WareHouse }) => {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING
    const params = new URLSearchParams({ ItemCode, WareHouse }).toString()

    const resultGetDestinyWarehouse = await window.fetch(`${requestPath}/back/routes/item.php?${params}`, {
        credentials: 'include'
    })

    const { result = [], message = '' } = await resultGetDestinyWarehouse.json()
    return { result, message }
}