import { useContext, useEffect, useRef, useState } from 'react'
import { Form, Col } from 'react-bootstrap'
import PickingContext from '../../context/pickingContext'

import { getDestinyWarehouse } from './services/getDestinyWarehouse'

function DestinyWarehouses() {

    const inputRef = useRef(null)
    const liRef = useRef(null)
    const [showWarehouses, setShowWarehouses] = useState([])
    const [filterWarehouses, setFilterWarehouses] = useState([])
    const [isFocused, setIsFocused] = useState(Boolean)

    const { destinyWarehose = [], setDestinyWarehouse, selectedDestinyWarehouse = {}, setSelectedDestinyWarehouse, setSupplierData } = useContext(PickingContext)

    const getDestinyWarehouseHandler = async () => {
        const { result = [], message = '' } = await getDestinyWarehouse({ CardCode: '' })
        if (result.length > 0) {
            setDestinyWarehouse(result)
            setShowWarehouses(result)
        }
    }

    const filterWarehousesHandler = (e) => {
        const filteredWares = destinyWarehose.filter(ware => (ware?.CardName.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1 || ware?.CardCode.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1))
        setFilterWarehouses(filteredWares)        
    }

    useEffect(() => {
        destinyWarehose.length === 0 && getDestinyWarehouseHandler()
    }, [])

    useEffect(() => {
        filterWarehouses.length ? setShowWarehouses(filterWarehouses) : setShowWarehouses(destinyWarehose)
        setSupplierData(selectedDestinyWarehouse);
    }, [filterWarehouses, inputRef.current?.value])

    return (
        <Col md={6}>
            <Form.Group controlId="destinyWarehouse" className="position-relative">
                <Form.Label className="mb-0">Almacen Destino</Form.Label>
                <Form.Control
                    className="form-control-sm"                    
                    onFocus={() => {
                        setIsFocused(true)
                        setTimeout(() => {inputRef.current.focus();
                        }, 200)
                        
                    }}
                  
                    placeholder="Sucursal Destino"
                    disabled={isFocused}
                    value={selectedDestinyWarehouse?.CardName}
                    style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderRadius: "0px" }}
                />
                {
                    isFocused &&
                    <>
                        <Form.Control
                            className="form-control-sm"
                            onChange={filterWarehousesHandler}
                            ref={inputRef}
                            placeholder="Escribí aquí para filtrar"
                            style={{ borderTop: "0px", borderLeft: "0px", borderRight: "0px", borderRadius: "0px", outline: "none" }}
                        />
                        <ol className="list-unstyled position-absolute overflow-auto w-100" style={{ maxHeight: "80px", zIndex: 2, backgroundColor: 'white' }}>
                            {
                                showWarehouses.length > 0 ?
                                    showWarehouses?.map(warehouse => <li
                                        ref={liRef}
                                        key={warehouse?.CardCode}
                                        className="mb-1 border-bottom px-2 text-truncate"
                                        title={warehouse?.CardName}
                                        role="button"
                                        onClick={() => {
                                            setSelectedDestinyWarehouse({ CardName: warehouse?.CardName, CardCode: warehouse?.CardCode })
                                            
                                            setIsFocused(false)
                                            setFilterWarehouses([])

                                        }}
                                    >
                                        {warehouse?.CardName}
                                        
                                    </li>)
                                    :
                                    <div><span className="spinner-grow spinner-grow-sm text-primary"></span><span>Cargando Almacenes...</span></div>
                            }
                        </ol>
                    </>}
            </Form.Group>
        </Col>
    )
}

export default DestinyWarehouses