export const getPickingByDocEntry = async ({ DocEntry, pickingType }) => {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING
    const resultGetPinkingByDocEntry = await window.fetch(`${requestPath}/back/routes/order.php?${new URLSearchParams({ DocEntry, pickingType }).toString()}`, {
        credentials: "include"
    })
        .catch(err => {
            console.log(err)
        })
    const { result = [], status = 0 } = await resultGetPinkingByDocEntry.json()
    return { result, status }
}