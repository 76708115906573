import { useContext } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useCookies } from 'react-cookie'
import PickingContext from '../../context/pickingContext'
import { formatDateOncChangeHandler } from '../utils/normalizeDate'

function FormFormatedPicking() {

    const [cookies] = useCookies()
    const { supplierData = {}, formatedEntryItems = {}, linesForDocumentLines = {}, setInventoryEntry } = useContext(PickingContext)


    const handlerSetInventoryEntry = (e) => {
        const fecha = formatDateOncChangeHandler(e)
        const value = e.target.name === "FolioNumberFrom" ? Number(e.target.value) : e.target.name === 'TaxDate' ? fecha : e.target.name === 'PointOfIssueCode' ? e.target.value : ''
        setInventoryEntry({
            ...formatedEntryItems,
            [e.target.name]: value,
            FolioNumberTo: e.target.name === "FolioNumberFrom" ? Number(e.target.value) : '',
            CardCode: supplierData?.CardCode,
            BPL_IDAssignedToInvoice: 66,
            BPLName: cookies.BPLName,
            DocumentLines: Object.entries(linesForDocumentLines).map(([, value]) => value)
        })
    }

    return (
        <Row>
            <Col>
                <p className="mb-0">Proveedor: {supplierData?.CardName}</p>
                <p>Código: {supplierData?.CardCode}</p>
            </Col>
            <Row>
                <Form.Group as={Col}>
                    <Form.Label className="p-0" column>
                        Fecha Remito:
                    </Form.Label>
                    <Form.Control
                        size="sm"
                        name="TaxDate"
                        placeholder="Fecha Remito"
                        value={formatedEntryItems?.TaxDate}
                        onKeyPress={e => (e.code.includes("Key") && e.ctrlKey === false) && e.preventDefault()}
                        onChange={handlerSetInventoryEntry}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className="p-0" column>
                        N&#176; Punto Venta:
                    </Form.Label>
                    <Form.Control size="sm" name="PointOfIssueCode" placeholder="N&#176; Punto de Venta"
                        onKeyPress={e => (e.code.includes("Key") && e.ctrlKey === false) && e.preventDefault()}
                        onChange={handlerSetInventoryEntry}
                    />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label className="p-0" column>
                        N&#176; de Folio:
                    </Form.Label>
                    <Form.Control size="sm" name="FolioNumberFrom" placeholder="N&#176; de Folio"
                        onKeyPress={e => (e.code.includes("Key") && e.ctrlKey === false) && e.preventDefault()}
                        onChange={handlerSetInventoryEntry}
                    />
                </Form.Group>
            </Row>
        </Row>
    )
}

export default FormFormatedPicking