export const saveItems = async ({ picking = {}, pickingType = '' }) => {
    const pickingFormated = Object.keys(picking).map(accesKey => ({ articleBarCode: accesKey, articleQuantity: picking[accesKey]?.quantity, articlePending: picking[accesKey]?.quantity }))
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultSaveItems = await window.fetch(`${requestPath}/back/routes/sqlPicking.php`, {
        method: "POST",
        body: JSON.stringify({ lines: pickingFormated, pickingType }),
        credentials: 'include'
    })

    const { result, message, error_catch } = await resultSaveItems.json()

    return { result, message, error_catch }
}