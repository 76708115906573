export const getDestinyWarehouse = async ({ CardCode = '' }) => {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING
    const params = new URLSearchParams({ CardCode }).toString()

    const resultGetDestinyWarehouse = await window.fetch(`${requestPath}/back/routes/warehouses.php?${params}`, {
        credentials: 'include'
    })

    const { result = [], message = '' } = await resultGetDestinyWarehouse.json()
    return { result, message }
}
