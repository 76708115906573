import React, { useContext, useEffect, useState } from 'react'
import { Form, Col, Row, Button } from 'react-bootstrap'
import PickingContext from '../../context/pickingContext'
import { formatDateOncChangeHandler, formatDateOnKeyPress, formatDateOnPasteHandler } from '../utils/normalizeDate'
import { getPickings } from './services/getPickings'
import Swal from 'sweetalert2'
import { useHistory, useParams } from 'react-router-dom'

function FormSearchPickingDetail() {

    const params = useParams()
    const { replace, location } = useHistory()
    const { setPickingFound } = useContext(PickingContext)

    const [typeSearch, setTypeSearch] = useState('Todos')
    const [betweenDates, setBetweenDates] = useState({})

    const onSubmitHandler = async e => {
        e.preventDefault()
        if (!betweenDates?.eDate && !betweenDates?.fDate || betweenDates?.eDate?.length < 10 || betweenDates?.fDate?.length < 10) return
        window.localStorage.setItem('fDate', betweenDates?.fDate)
        window.localStorage.setItem('eDate', betweenDates?.eDate)
        const initialPath = location.pathname.slice(1, location.pathname.length).split('/', 1)
        replace(`/${initialPath}/${betweenDates.fDate.replaceAll('/', '-')}/${betweenDates.eDate.replaceAll('/', '-')}/${typeSearch}/`)
        const { result = [], message = "" } = await getPickings({ ...betweenDates, typeSearch })
        if (message.includes('Successfully')) setPickingFound(result)
        if (result.length === 0) Swal.fire('No se encontró ningún picking', 'Por favor, controlá las fechas ingresadas', 'info')

    }

    const chageTypeSearchHandler = e => {
        const { value } = e.target
        setTypeSearch(value)
    }

    const handlerChange = e => {
        const formatedDate = formatDateOncChangeHandler(e)
        if (!!formatedDate) {
            setBetweenDates({ ...betweenDates, [e.target.name]: formatedDate })
        } else {
            setBetweenDates({ ...betweenDates, [e.target.name]: '' })
        }
    }

    const handlerOnPaste = e => {
        const formatedDate = formatDateOnPasteHandler(e)
        if (!!formatedDate) {
            setBetweenDates({ ...betweenDates, [e.target.name]: formatedDate })
        } else {
            setBetweenDates({ ...betweenDates, [e.target.name]: '' })
        }
    }

    const handlerOnKeyPress = e => {
        formatDateOnKeyPress(e)
    }

    const date = new Date()

    const formatedDate = `${date.getDate().toString().padStart(2, '0')}/${date.getMonth().toString().padStart(2, '0')}/${date.getFullYear()}`

    useEffect(() => {
        setBetweenDates({
            fDate: !params?.fDate ? window.localStorage.getItem('fDate') : params?.fDate.replaceAll('-', '/'),
            eDate: !params?.eDate ? window.localStorage.getItem('eDate') : params?.eDate.replaceAll('-', '/')
        })
        if (!!params?.typeSearch) setTypeSearch(params?.typeSearch)
    }, [])

    useEffect(() => {
        if (!!typeSearch && !!betweenDates?.fDate && !!betweenDates?.eDate) {
            const initialPath = location.pathname.slice(1, location.pathname.length).split('/', 1)
            replace(`/${initialPath}/${betweenDates.fDate.replaceAll('/', '-')}/${betweenDates.eDate.replaceAll('/', '-')}/${typeSearch}/`)
        }
    }, [typeSearch])

    return (
        <form className="border px-3 px-md-5 py-3 row mx-0 align-items-center justify-content-around w-100" onSubmit={onSubmitHandler}>
            <Col xs="12" md="4">
                <fieldset className="align-items-center">
                    <Form.Group>
                        <Form.Check
                            type="radio"
                            label="Todos"
                            name="typePickingDetailSeach"
                            id="typePickingDetailSeach1"
                            // defaultChecked={!!typeSearch === false}
                            value="Todos"
                            checked={typeSearch === "Todos"}
                            onChange={chageTypeSearchHandler}
                        />
                        <Form.Check
                            type="radio"
                            label="Pendientes"
                            name="typePickingDetailSeach"
                            id="typePickingDetailSeach2"
                            value="Pendiente"
                            checked={typeSearch === "Pendiente"}
                            onChange={chageTypeSearchHandler}
                        />
                        <Form.Check
                            type="radio"
                            label="Ingresados"
                            name="typePickingDetailSeach"
                            id="typePickingDetailSeach3"
                            value="Ingresado"
                            checked={typeSearch === "Ingresado"}
                            onChange={chageTypeSearchHandler}
                        />
                        <Form.Check
                            type="radio"
                            label="Cancelados"
                            name="typePickingDetailSeach"
                            id="typePickingDetailSeach4"
                            value="Cancelado"
                            checked={typeSearch === "Cancelado"}
                            onChange={chageTypeSearchHandler}
                        />
                    </Form.Group>
                </fieldset>
            </Col>
            <Col xs="12" md="4">
                <Row xs={12} className="mx-0 flex-md-nowrap align-items-center">
                    <Form.Label className="text-nowrap mr-3 mt-2">F. desde</Form.Label>
                    <Form.Control className="w-100"
                        placeholder={`Ej: ${formatedDate}`}
                        size="sm"
                        maxLength="10"
                        value={betweenDates?.fDate}
                        name="fDate"
                        onChange={handlerChange}
                        onKeyDown={handlerOnKeyPress}
                        onPaste={handlerOnPaste}
                    />
                </Row>
                <Row xs={12} className="mx-0 flex-md-nowrap align-items-center">
                    <Form.Label className="text-nowrap mr-3 mt-2">F. Hasta </Form.Label>
                    <Form.Control className="w-100"
                        placeholder={`Ej: ${formatedDate}`}
                        size="sm"
                        maxLength="10"
                        value={betweenDates?.eDate}
                        name="eDate"
                        onChange={handlerChange}
                        onKeyDown={handlerOnKeyPress}
                        onPaste={handlerOnPaste}
                    />
                </Row>
            </Col>
            <Row className="justify-content-end mt-3 mt-md-0 col-12 col-md-4">
                <Button className="" variant="primary" type="submit" onClick={onSubmitHandler}>Buscar</Button>
            </Row>
        </form>
    )
}

export default FormSearchPickingDetail