import Notiflix from "notiflix"
import { useContext } from "react"
import { Button, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2"
import PickingContext from "../../context/pickingContext"
import { useFormatTransferOrder } from "../../hooks/useFormatTransferOrder"
import { editPickingDetails } from '../consultTab/services/editPicking'
import { postPickingTransfer } from "./services/postPickingTransfer"

function ButtonSavePickingExit() {


    const history = useHistory()
    const { pickingFound = [], setPickingFound, pickingDetail = [], selectedDestinyWarehouse = {},linesForDocumentLines, selectedOriginWarehouse = '', selectedPicking = {}, selectedPointOfIssueCode = '', setSelectedPointsOfIssueCode, setSelectedOriginWarehouse, setSelectedDestinyWarehouse,setFormatedTransferOrder } = useContext(PickingContext)



    const { formatedTransferOrder } = useFormatTransferOrder({ pickingDetail,selectedDestinyWarehouse, selectedOriginWarehouse, selectedPointOfIssueCode })

    const allPickingQuantityOk = pickingDetail.filter(article => article.articleStatus === '1')
        .every(picking => picking?.articleQuantity <= picking?.InStockQuantity)

    const savePickingTransferHandler = async () => {
        Notiflix.Block.dots('#tab-container', 'Cargando Transferencia de Stock...')
        const { result = [], message = '', error_msg = '' } = await postPickingTransfer(formatedTransferOrder)
        if (message.includes('Successfully') && result.length > 0) {
            const { message = '' } = await editPickingDetails({ entrySAP: result[0]?.DocEntry,DocNum: result[0]?.DocNum, pickingCode: selectedPicking?.pickingCode})
            if (message.includes('Successfully')) {
                const newPickingsFound = pickingFound.map(picking => picking?.pickingCode === selectedPicking?.pickingCode && (picking.pickingStatus = 'Ingresado'))
                setPickingFound(newPickingsFound)
            }
            Swal.fire({ title: 'Transferencia Exitosa',  showConfirmButton: true,
            text: `La transferencia de Stock se realizó Correctamente. Transferencia de Stock Nro: ${result[0]?.DocNum} `,  })
            setSelectedPointsOfIssueCode('')
            setSelectedOriginWarehouse('')
            setSelectedDestinyWarehouse({})
            history.go('/consultas')
        }
        if (!!error_msg) Swal.fire({ title: 'Ocurrió un error', text: error_msg })
        Notiflix.Block.remove('#tab-container')
    }

    const { CardCode = '', FromWarehouse = '', PointOfIssueCode = '' } = formatedTransferOrder

    return (
        <Row className="justify-content-end mx-0">
            {
               Object.keys(linesForDocumentLines).length > 0 && 
                <Button
                    variant="primary"
                    onClick={savePickingTransferHandler}
                >
                    Guardar
                </Button>
            }
        </Row>
    )
}

export default ButtonSavePickingExit