import XLSX from 'xlsx'

export const exportToExcel = (data = []) => {

    const formatedData = data.map(({ articleBarCode, articleQuantity, ItemCode, ItemName }) => ({ 'Cod. Barra': articleBarCode, 'Cod. Articulo': ItemCode, 'Descripcion': ItemName, 'Cantidad': articleQuantity }))
    const ws = XLSX.utils.json_to_sheet(formatedData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "picking")
    return XLSX.writeFile(wb, `picking_${data[0].pickingCode}.xlsx`)
}
