export const getBusinessData = async ({ type, reference }) => {
    const paramsRequests = new URLSearchParams({ type, reference }).toString()
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    try {
        const resultOrder = await window.fetch(`${requestPath}/back/routes/business.php?${paramsRequests}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        })
        const { result = [] } = await resultOrder.json()
        return { result }
    } catch (error) {
        console.log(error)
        return error
    }
}
