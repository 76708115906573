import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"

export function useFormatTransferOrder2({ pickingDetail = [], selectedDestinyWarehouse = {}, selectedOriginWarehouse = '', selectedPointOfIssueCode = '' }) {

    const dictionaryProjectCode = {
        DM: 'Motos',
        DH: 'Hogar',
        DR: 'Repuestos',
        DB: 'Bikes',
        DS: 'Servicio_Postventa',
    }

    const [cookies] = useCookies()
    const [formatedTransferOrder, setFormatedTransferOrder] = useState({})

    useEffect(() => {
        const lines = pickingDetail.filter(article => article.articleStatus === '1')
            .map(line => ({ ItemCode: line?.ItemCode, Quantity: line?.articleQuantity, WarehouseCode: `${Number(selectedOriginWarehouse || 0) + 500}`, FromWarehouseCode: selectedOriginWarehouse, ProjectCode: dictionaryProjectCode[line?.U_Division] }))

        setFormatedTransferOrder({
            CardCode: selectedDestinyWarehouse?.CardCode,
            BPLID: cookies?.BPLID,
            BPLName: cookies?.BPLName,
            PointOfIssueCode: selectedPointOfIssueCode,
            FromWarehouse: selectedOriginWarehouse,
            ToWarehouse: `${Number(selectedOriginWarehouse || 0) + 500}`,
            StockTransferLines: lines

        })
    }, [pickingDetail, selectedDestinyWarehouse, cookies])

    return { formatedTransferOrder }
}

