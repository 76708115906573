import { Divider } from 'antd'
import React, { useContext, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import PickingContext from '../../context/pickingContext'
import FormSearchPickingDetailExit from './FormSearchPickingDetailExit'
import TableConsultExit from './TableConsultExit'

function ConsultTabExit() {

    const { setSelectedPointsOfIssueCode, setSelectedOriginWarehouse, setSelectedDestinyWarehouse, setPickingDetail, setSupplierData, setLinesForDocumentLines } = useContext(PickingContext)

    useEffect(() => {
        setSelectedPointsOfIssueCode('')
        setSelectedOriginWarehouse('')
        setSelectedDestinyWarehouse({})
        setPickingDetail([])
        setSupplierData({})
        setLinesForDocumentLines({})
    }, [])

    return (
        <Container className="rounded p-2 p-md-5" id="tab-container">
            <FormSearchPickingDetailExit />
            <Divider />
            <TableConsultExit />
        </Container>
    )
}

export default ConsultTabExit