export const getPickingDetails = async ({ pickingCode }) => {

    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultGetPinkingDetail = await window.fetch(`${requestPath}/back/routes/sqlPicking.php?${new URLSearchParams({ pickingCode }).toString()}`)
        .catch(err => {
            console.log(err)
        })
    const { result = [], message = "", error_msg = "" } = await resultGetPinkingDetail.json()
    return { result, message, error_msg }
}