const getPrevText = (text, discountText) => {
    return text.slice(0, (text.length - discountText))
}

const checkStringData = (text) => {
    if (text.length === 1) {
        if (Number(text) > 3) {
            return ''
        }
        return text
    }
    if (text.length === 2) {
        if (Number(text) > 31) {
            return getPrevText(text, 1)
        }
        return text + '/'
    }
    if (text.length > 3) {
        const month = text.split('/')[1]
        const year = text.split('/')[2]
        if (text.length === 4) {
            if (Number(month) > 1) {
                return getPrevText(text, 1)
            }
            return text
        }
        if (text.length === 5) {
            if (Number(month) > 12) {
                return getPrevText(text, 2)
            }
            return text + '/'
        }
        if (text.length > 5 && text.length < 10) {
            return text
        }
        if (text.length === 10) {
            if (Number(year) > new Date().getFullYear()) {
                return getPrevText(text, 4)
            }
            return text
        }
    }
}

export const formatDateOncChangeHandler = e => {
    let text = e.target.value || ''
    if (!Number.isNaN(Number(e.nativeEvent.data))) {
        if (!e.nativeEvent.inputType.includes('delete')) {
            if (text.length === 2) {
                text += '/'
                return text
            }
            if (text.length === 5) {
                text += '/'
                return text
            }
        }
    } else {
        e.preventDefault()
    }
    return text

}

export const formatDateOnPasteHandler = e => {
    if (e.clipboardData.getData('Text')?.length === 10) {
        if (e.clipboardData.getData('Text').split("/").length === 3) {
            return e.clipboardData.getData('Text')
        }
    } else {
        if (Number.isNaN(Number(e.clipboardData.getData('Text')))) {
            e.preventDefault()
            return false
        }
        if (e.clipboardData.getData('Text').length === 8) {
            const firstCharacterDate = checkStringData(e.clipboardData.getData('Text').slice(0, 1))
            const date = checkStringData(e.clipboardData.getData('Text').slice(0, 2))
            const firstCharacterMonth = checkStringData((date + e.clipboardData.getData('Text').slice(2, 3)))
            const month = checkStringData((date + e.clipboardData.getData('Text').slice(2, 4)))
            if (firstCharacterDate.length !== 1) {
                return firstCharacterDate
            }
            if (firstCharacterMonth.length !== 4) {
                return firstCharacterMonth
            }
            if (date.length === 3) {
                if (month.length === 6) {
                    const text = e.clipboardData.getData('Text').slice(0, 2) + '/' + e.clipboardData.getData('Text').slice(2, 4) + '/' + e.clipboardData.getData('Text').slice(4, 8)
                    return checkStringData(text)
                }
            }
        }
    }
}

export const formatDateOnKeyPress = e => {
    if (e?.code?.includes('Key') && !!e.ctrlKey === false) {
        e.preventDefault()
        return false
    }
}
