import { useContext } from 'react'
import TableComponent from '../TableComponent'
import PickingContext from '../../context/pickingContext'
import { pickingStatus } from '../utils/pickingStatus'
import ActionsForTableConsultExit from './actionsForTableConsultExit'
import PickingTableEdit from '../pickingTab/PickingTableEdit'
import { useCookies } from 'react-cookie'

import { useParams } from 'react-router-dom'


function ConsultTable({ loadingTable }) {

    const { pickingFound = [] } = useContext(PickingContext)
    const [cookies] = useCookies()

    const params = useParams()

    const dictionary = {
        pickingCode: 'Cod. Picking',
        brandName: 'Sucursal',
        userName: 'Usuario',
        pickingType: 'Tipo',
        pickingStatus: 'Estado',
        fullDatePicking: 'Fecha Picking'
    }

    const columns = Object.entries(dictionary)
        .map(([accessKey, label]) => ({
            title: label,
            dataIndex: accessKey,
            key: accessKey,
            width: accessKey === 'brandName' ? '10%' : '10%',
            align: 'center',
            ellipsis: accessKey === 'brandName' ? true : false
        }));

    return (
        <>
            <TableComponent columns={columns}
                scroll={{ x: true }}
                keyTable="pickingCode"
                data={pickingFound
                    .filter(picking => params?.typeSearch === 'Todos'&& picking.brandCode === cookies?.BPLName ? true : picking.pickingStatus === 'Pendiente' && picking.pickingType === 'Egreso' && picking.brandCode === cookies?.BPLName)
                }
                
                action={[
                    {
                        title: 'Acción',
                        align: 'center',
                        width: '10%',
                        render: rowData => <ActionsForTableConsultExit rowData={rowData} />
                    }]
                }
                addSearch={pickingFound?.length > 5}
                loading={loadingTable}
            />
        </>
    )
}

export default ConsultTable