import React from 'react'
import "./index.css"
import App from './App'
import Notiflix from 'notiflix'
import ReactDOM from 'react-dom'

Notiflix.Loading.init({
  messageColor: 'white',
  svgColor: '#354a5f',
  backgroundColor: 'rgba(0,0,0,0.3)',
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)