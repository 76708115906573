import React, { useContext } from 'react'
import TableComponent from '../TableComponent'
import PickingContext from '../../context/pickingContext'

function PickingTable({ loadingTable }) {

    const { picking = {}, processInputPicking, removeCurrentPickingHandler } = useContext(PickingContext)

    const data = Object.entries(picking)
        .map(([accesKey]) => {
            return { ...picking[accesKey], BarCode: accesKey }
        })

    let columns = []

    if (data.length > 0) {

        const dictionary = {
            quantity: 'Cantidad',
            ItemCode: 'Cod. Prod',
            ItemName: 'Producto',
            BarCode: 'Cod. Barras',

        }

        columns = Object.keys(data.flat()[0])
            .map(key => {
                if (key !== 'key') {
                    return {
                        title: dictionary[key],
                        dataIndex: key,
                        key: key,
                        width: dictionary[key] === 'Producto' ? '60%' : '20%',
                        align: 'center',
                        ellipsis: dictionary[key] === 'Producto' ? true : false
                    }
                }
            })
    }

    const FooterTable = ({ data }) => {
        const suma = data.reduce((accum, val) => accum + val.quantity, 0)

        return (
            <>
                <p className="mb-0">Total de Lineas: {data.length > 0 ? data.length : 'No hay datos en la tabla'} </p>
                <p className="mb-0">Total de Articlos: {suma > 0 ? suma : 'No hay datos en la tabla'} </p>
            </>
        )
    }

    return (
        <TableComponent columns={columns}
            data={data.flat()}
            action={[{
                title: 'Acción',
                align: 'center',
                render: rowData => <div className="row mx-0 align-items-center justify-content-center">
                    <i title="Sumar" className="far fa-plus-square btn btn-outline-primary pt-2 mx-1" style={{ fontSize: '12px' }} onClick={() => processInputPicking({ pickingValue: rowData.BarCode })}></i>
                    <i title="Restar" className="fas fa-minus-circle btn btn-outline-secondary pt-2 mx-1" style={{ fontSize: '12px' }} onClick={() => processInputPicking({ pickingValue: rowData.BarCode, pickingQuantity: ((rowData.quantity - 1) > -1 ? (rowData.quantity - 1) : 0) })}></i>
                    <i title="Borrar" className="far fa-trash-alt btn btn-outline-danger pt-2 mx-1" style={{ fontSize: '12px' }} onClick={() => removeCurrentPickingHandler(rowData.BarCode)}></i>
                </div>,
                width: '28%'
            }]}
            keyTable="BarCode"
            addSearch={true}
            loading={loadingTable}
            FooterTable={FooterTable}
        />
    )
}

export default PickingTable