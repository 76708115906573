import { useState } from 'react'
import { Tab } from 'react-bootstrap'

function TabsComponent({ children }) {

    const [key] = useState("picking")

    return (
        <Tab.Container
            defaultActiveKey={key}
            className="mb-3"
        >
            {children}
        </Tab.Container>
    )
}

export default TabsComponent