import { useContext, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { Button } from 'react-bootstrap'
import GlobalContext from '../globalcontext/globalContext'
import { Link, useHistory } from 'react-router-dom'

function NavbarComponent() {
    const [cookies, , removeCookie] = useCookies([])
    const { userData, setIslogedInHandler } = useContext(GlobalContext)

    const history = useHistory()

    useEffect(() => {
        if (!!cookies?.B1SESSION && !!cookies?.ROUTEID) setIslogedInHandler(true)
        else setIslogedInHandler(false)
    }, [cookies])

    return (
        <div className="row justify-content-around py-1 mx-0"
            style={{
                backgroundColor: "#fff",
                boxShadow: "0 1rem 3rem rgba(0, 0, 0, .175)"
            }}
        >
            {
                userData &&
                <>
                    <div></div>
                    <Link to="/"><h4 className="text-secondary mb-0">APP de Picking</h4></Link>
                    <Button variant="outline-primary" onClick={() => {
                        Object.entries(cookies).forEach(([key]) => removeCookie(key, { path: '/' }))
                        setIslogedInHandler(false)
                        history.replace('/')
                    }}>Salir</Button>
                </>
            }
        </div>
    )
}

export default NavbarComponent