export const getPendingOrdersEgreso = async ({ CardCode, ItemCode, BPLName }) => {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultOrders = await window.fetch(`${requestPath}/back/routes/order_egreso.php?${new URLSearchParams({ CardCode, ItemCode, BPLName }).toString()}`, {
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include'
    })
    const { result, message = "" } = await resultOrders.json()
    return { result, message }
}