import HeaderTab from './HeaderTab'
import { useCookies } from 'react-cookie'

function AppLayout({ children }) {
    const [cookies] = useCookies()
    return (
        <div>
            {(!!cookies?.BPLName && !!cookies?.B1SESSION && !!cookies?.ROUTEID) && <HeaderTab />}
            {children}
        </div>
    )
}

export default AppLayout