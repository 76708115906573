import { Divider } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PickingContext from '../../context/pickingContext'
import TableOfPickingForValidationEgreso from './TableOfPickingForValidationEgreso'
import TableOfPickingForValidation from './TableOfPickingForValidation'
import ModalSupplier from './ModalSupplier'
import FormWarehouse from './FormWarehouse'
import ButtonSavePickingExit from './ButtonSavePickingExit'
import ButtonSavePickingExit2 from './ButtonSavePickingExit2'

import { useCookies } from 'react-cookie'

function ValidationTabEgreso() {

    const history = useHistory()
    const { pickingDetail = [], supplierData = {}, selectedPicking = {}, setSupplierData, setSelectedDestinyWarehouse, setSelectedOriginWarehouse, clearLinesForDocumentLines } = useContext(PickingContext)

    const [cookies] = useCookies()


    const [showModal, setShowModal] = useState(false)

    const setModalIsVisibleHandler = (bool = false) => {
        setShowModal(bool)
    }

    console.log('cookies?.BPLID', cookies?.BPLID)
    useEffect(() => {
        if (pickingDetail.length === 0) history.goBack()
    }, [pickingDetail])

    useEffect(() => {
        setSupplierData({})
        // setPickingDetail([])
        setSelectedDestinyWarehouse({})
        setSelectedOriginWarehouse('')
        clearLinesForDocumentLines({})
    }, [])

    return (
        <Container className="rounded p-2 p-md-5 container-validation" id="tab-container">
            {
                Object.keys(supplierData).length > 0 
            }
            {
                selectedPicking?.pickingType === "Egreso" && <FormWarehouse />
            }
            <Divider />
            {     
                  cookies?.BPLName =='010' ?
            <TableOfPickingForValidationEgreso setModalIsVisibleHandler={setModalIsVisibleHandler} /> : <TableOfPickingForValidation setModalIsVisibleHandler={setModalIsVisibleHandler} /> 
            }
            {
                selectedPicking?.pickingType !== "Egreso" &&
                <ModalSupplier
                    setModalIsVisibleHandler={setModalIsVisibleHandler}
                    showModal={showModal}
                />
            }

            {selectedPicking?.pickingType === "Egreso" &&
            cookies?.BPLName =='010' ?
            
            <ButtonSavePickingExit/>: <ButtonSavePickingExit2 />
        
        }
        </Container>
    )
}

export default ValidationTabEgreso