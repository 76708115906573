export const getPickings = async ({ fDate, eDate, typeSearch, pickingCode, pickinHeader }) => {

    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const params = new URLSearchParams({ fDate, eDate, typeSearch, pickingCode, pickinHeader }).toString()
    const resultGetPinking = await window.fetch(`${requestPath}/back/routes/sqlPicking.php?${params}`)
        .catch(err => {
            console.log(err)
        })
    const { result = [], message = "", error_msg = "" } = await resultGetPinking.json()
    return { result, message, error_msg }
}