import { Divider } from 'antd'
import { memo, useContext, useRef, useState } from 'react'
import { Container, Row, Button } from 'react-bootstrap'
import pickingContext from '../../context/pickingContext'
import ToastComponent from '../ToastComponent'
import FormGetItem from './FormGetItem'
import PickingTable from './PickingTable'
import { saveItems } from './services/saveItems'
import SwitchPickingType from './SwicthPickingType'
import Swal from 'sweetalert2'

function PickingTab() {

    const toastRef = useRef(null)

    const [loadingTable, setLoadingTable] = useState(false)
    const [show, setShow] = useState(false)
    const [toastMessage, setToastMessage] = useState({})

    const { picking = {}, pickingType, removeCurrentPickingHandler } = useContext(pickingContext)

    return (
        <Container className="p-2 p-md-5 rounded" id="tab-container">
            <ToastComponent
                show={show}
                setShow={() => setShow(false)}
                message={toastMessage?.message}
                title={toastMessage?.title}
                color={toastMessage?.color}
                reference={toastRef}
            />
            <SwitchPickingType />
            <FormGetItem setLoadingTable={setLoadingTable} />
            <Divider />
            <PickingTable loadingTable={loadingTable} />
            {
                Object.keys(picking).length > 0 &&
                <Row className="justify-content-center mt-3">
                    <Button variant="success" onClick={async () => {
                        const { message = ""} = await saveItems({ picking, pickingType })
                        if (message.includes("Successfully")) {
                            setShow(true)
                            Swal.fire({
                                icon: 'success',
                                title: 'Operación Exitosa!',
                                text: ` Picking cargado correctamente`
                            })
                            toastRef.current?.scrollIntoView(true, { behavior: "smooth" })
                            removeCurrentPickingHandler('')
                        } else Swal.fire({
                            icon: 'error',
                            title: 'Ocurrió un error',
                            text: ` Falta ingresar tipo de Picking`
                        })

                    }} >Ingresar Productos</Button>
                </Row>
            }
        </Container>
    )
}


export default memo(PickingTab)