import { useContext, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2"
import PickingContext from "../../context/pickingContext"
import ModalComponent from "../ModalComponent"
import { cancelPickingHandler } from "./services/cancelPicking"

function ModalCancelPicking({ cancelPickingDetail, setCancelPickingDetail }) {

    const { cancelledWith = '', cancelComment = '' } = cancelPickingDetail
    const history = useHistory()
    const { cancelPicking = {}, pickingFound = [], setPickingFound, setCancelPicking } = useContext(PickingContext)

    const confirmCancelPicking = async () => {
        if (Object.keys(cancelPicking).length !== 3) {
            return
        } else {
            const { message = "" } = await cancelPickingHandler({ ...cancelPicking, pickingStatus: 'Cancelado' })
            if (message.includes('Successfully') && cancelPicking.cancelledWith > cancelPicking.pickingCode) {
                const newPickingsFound = pickingFound.map(picking => picking?.pickingCode === cancelPicking?.pickingCode && (picking.pickingStatus = 'Cancelado'))
                setPickingFound(newPickingsFound)
                Swal.fire({
                    icon: "success",
                    title: "Picking Cancelado con exito"
                })
                history.replace("/consultas")
            }
            else{
                Swal.fire({
                    icon: "error",
                    title: "N° Picking inexistente o menor al que se quiere cancelar",
                })            }
        }
    }
    

    const askForCancelPicking = () => {
        if (Object.keys(cancelPicking).length === 3) {
            Swal.fire({
                icon: "warning",
                title: "Estás por cancelar un picking",
                text: "¿Estás seguro/a?",
                showCancelButton: true,
                cancelButtonText: "Cancelar"
            }).then(result => {
                if (result.isConfirmed) confirmCancelPicking()
                // result.isDismissed && 
                // setCancelPicking({ pickingStatus: '2' })
            })
        } else {
            Swal.fire({
                icon: "error",
                title: "Todos los campos son obligatorios",
            })
        }
    }

    return (
        <ModalComponent
            modalIsVisible={!!cancelPicking?.pickingCode || !!cancelledWith}
            handleClose={() => {
                setCancelPicking({ pickingStatus: 'Cancelado' })
                setCancelPickingDetail({})
            }}
            handleConfirm={askForCancelPicking}
            showCancelButton={false}
            showConfirmButton={!(!!cancelledWith)}
            modalTitle={!!cancelPicking?.pickingCode ? 'Cancelar Picking N° ' + cancelPicking?.pickingCode : "Cancelado Con:"}
        >
            <Form.Group>
                <Form.Label>N° de picking</Form.Label>
                <Form.Control
                    placeholder="N° Picking"
                    className="col-12 col-md-2"
                    name="cancelledWith"
                    defaultValue={!!cancelPicking?.canceledWith ? cancelPicking.canceledWith : !!cancelledWith ? cancelledWith : ''}
                    disabled={!!cancelledWith}
                    required
                    onChange={e => setCancelPicking({ ...cancelPicking, [e.target.name]: e.target.value })}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Observación</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Ingresar un comentario para poder cancelar este picking"
                    name="cancelComment"
                    defaultValue={!!cancelPicking?.canceledComment ? cancelPicking.canceledComment : !!cancelComment ? cancelComment : ''}
                    disabled={!!cancelComment}
                    required
                    onChange={e => setCancelPicking({ ...cancelPicking, [e.target.name]: e.target.value })}
                />
            </Form.Group>
        </ModalComponent>
    )
}

export default ModalCancelPicking