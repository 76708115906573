export async function postOrder(order) {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING
    const resultPostData = await window.fetch(`${requestPath}/back/routes/order.php`, {
        method: "POST",
        body: JSON.stringify({ order }),
        credentials: "include"
    })

    const { result = [], status = 0, error_msg } = await resultPostData.json()
    return { result, status, error_msg }
}

export default postOrder