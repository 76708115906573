export const editPickingLineQuantity = async ({ pickingID, articleQuantity }) => {
    const requestPath =
        process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING

    const resultEditPickingLineQuantity = await window.fetch(`${requestPath}/back/routes/sqlPicking.php`,
        {
            method: "PUT",
            body: JSON.stringify({ pickingID, articleQuantity })
        }
    )

    const { result, message } = await resultEditPickingLineQuantity.json()
    return { result, message }
}
