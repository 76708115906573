import { Modal, Button } from 'react-bootstrap'
import { useContext,} from 'react'
import PickingContext from '../context/pickingContext'

function ModalComponent({ children, modalIsVisible, handleClose, handleConfirm, modalTitle, showConfirmButton = true, keyboard = true, backdrop = true, showCancelButton = true }) {
    
    const {  selectedDestinyWarehouse = {}, setSelectedDestinyWarehouse} = useContext(PickingContext)


     function Cerrar(){
        setSelectedDestinyWarehouse({CardName:selectedDestinyWarehouse.CardName, CardCode:selectedDestinyWarehouse.CardCode,})


       

        handleClose()
     }

    return (
        <>
            <Modal
                show={modalIsVisible}
                onHide={handleClose}
                centered
                size="lg"
                keyboard={keyboard}
                backdrop={backdrop}
            >
                {
                    modalTitle &&
                    <Modal.Header closeButton>
                        <Modal.Title>{modalTitle}</Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body className={`overflow-auto`}>
                    {children}
                </Modal.Body>
                <Modal.Footer>
                    {
                        showCancelButton &&
                        <Button variant="secondary" onClick={() =>  Cerrar()}
                        >
                            
                            Cerrar
                        </Button>
                    }
               
                    {
                        showConfirmButton &&
                        <Button variant="primary" onClick={handleConfirm ? handleConfirm : handleClose}>
                            Confirmar
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalComponent