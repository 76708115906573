import { useContext, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PickingContext from '../../context/pickingContext'
import TableComponent from '../TableComponent'
import ToastComponent from '../ToastComponent'
import ActionsForPickingsForValidation from './actionsForPickingsForValidation'
import { getItemService } from '../pickingTab/services/getItemService'
import { useCookies } from 'react-cookie'
import { getPendingOrdersEgreso } from './services/getPendingOrdersEgreso'
import ModalEditQuantityLine from './ModalEditQuantityLine'
import ModalAssignOrderNumberEgreso from './ModalAssignOrderNumberEgreso'
import ButtonExportToExcel from './ButtonExportToExcel'

function TableOfPickingForValidationEgreso() {

    const history = useHistory()
    const [cookies, removeCookies] = useCookies()

    const [currentPicking, setCurrentPicking] = useState({})
    const [currentPickingLine, setCurrentPickingLine] = useState({})


    const { pendingOrders = [], pickingDetail = [], linesForDocumentLines = {}, supplierData = {}, selectedPicking = {}, setPickingDetail, setSupplierData, setLinesForDocumentLines } = useContext(PickingContext)
    const toastRef = useRef(null)

    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const dictionary = {
        articleBarCode: "Cod. de Barra",
        ItemCode: "Cod. Articulo",
        ItemName: "Descripción",
        articleQuantity: "Cant. Picking",
    }


    const [tableData, setTableData] = useState({ columns: [] })
    useEffect(() => {
        const columnsData = Object.keys(dictionary).map(key => ({
            title: dictionary[key],
            dataIndex: key,
            key: key,
            ellipsis: true,
            align: "center",
            width: key == "ItemName" ? "20%" : "15%"
        }))
        const stockColumn = { title: "Stock", key: "InStockQuantity", dataIndex: "InStockQuantity", align: "center", width: "25%" }
        setTableData({ columns: selectedPicking?.pickingType !== "Egreso" ? columnsData : [...columnsData, stockColumn] })
    }, [pickingDetail])

 
console.log('pickingDetailpickingDetail', pickingDetail)
    const getPendingOrdersEgresoHandler = async () => {
        setIsLoading(true)
        let lines = {}
        for (const picking of pickingDetail) {
            const { result = [] } = await getPendingOrdersEgreso({ ItemCode: picking?.ItemCode?.trim(), CardCode: supplierData?.CardCode?.trim(), BPLName: cookies.BPLName })
            if (result.length === 1) {
                if (Object.keys(lines).length === 0) {
                    lines = {
                        [result[0].NroSolicitud + "-" + result[0].DocEntry + '-' + result[0].ItemCode]: {
                            ItemCode: result[0]?.ItemCode,
                            Quantity: Number(picking?.articleQuantity),
                            WarehouseCode: cookies?.BPLName,
                            ProjectCode: result[0]?.Project,
                            BaseType: 1250000001,
                            BaseEntry: result[0]?.DocEntry,
                            BaseLine: result[0]?.LineNum,

                     
                        }
                    }
                } else {
                    const everyLineTypeIsOk = Object.keys(lines).every(accesKey => {
                        const { BaseType } = lines[accesKey]
                        return BaseType == 1250000001
                    })
                    if (everyLineTypeIsOk) lines = {
                        ...lines, [result[0].NroSolicitud + "-" + result[0].DocEntry + '-' + result[0].ItemCode]: {
                            ItemCode: result[0]?.ItemCode,
                            Quantity: Number(picking?.articleQuantity),
                            WarehouseCode: cookies?.BPLName,
                            ProjectCode: result[0]?.Project,
                            BaseType: 1250000001,
                            BaseEntry: result[0]?.DocEntry,
                            BaseLine: result[0]?.LineNum,


                        }
                    }
                }
            }
        }
        setLinesForDocumentLines({ ...linesForDocumentLines, ...lines })
        setIsLoading(false)
    }






    const getDetailSAP = async (filteredPickingDetail) => {
        setIsLoading(true)
        for (const picking of filteredPickingDetail) {
            const { result = [], error_msg = "" } = await getItemService({ barCode: picking.articleBarCode })
            // console.log(result.length ? "sale true" : "sale false")
            if (result.length) {
                Object.assign(picking, result[0])
                setPickingDetail([...pickingDetail])
            } else {
                const rowTable = document.querySelector(`tr[data-row-key="${picking.pickingID}"] > td`)
                if (rowTable) {
                    rowTable.style.background = 'red'
                    rowTable.style.color = 'white'
                    rowTable.style['font-weight'] = 'bold'
                }
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (Object.keys(supplierData).length > 0) return getPendingOrdersEgresoHandler()
    }, [supplierData])

    useEffect(() => {
        const filteredPickingDetail = pickingDetail.filter(({ articleStatus }) => articleStatus === '1')
        getDetailSAP(filteredPickingDetail)
    }, [])

    return (
        <>
            <ToastComponent
                color="bg-danger"
                message="Uno o más elementos no fueron encontrados en SAP, cargar Código de Barra para poder continuar"
                title="Ocurrió un error"
                show={showError}
                reference={toastRef}
                delay={2500}
                setShow={() => {
                    setShowError(false)
                    setPickingDetail([])
                    setSupplierData({})
                    history.goBack()
                }}
            />
            
                <p>Codigo Picking:&nbsp; <strong>{pickingDetail[0]?.pickingCode}</strong></p>
            <TableComponent data={pickingDetail.filter(picking => picking.articleStatus === '1')}
                columns={[...tableData.columns, {
                    title: "Acción",
                    key: "accion",
                    align: "center",
                    width: "40%",
                    render: rowData => <ActionsForPickingsForValidation
                        rowData={rowData}
                        setIsLoading={setIsLoading}
                        setCurrentPicking={setCurrentPicking}
                        setCurrentPickingLine={setCurrentPickingLine}
                    />
                }]}
                keyTable="pickingID"
                loading={isLoading}
                FooterTable={ButtonExportToExcel}
            />
            <ModalEditQuantityLine
                currentPickingLine={currentPickingLine}
                setCurrentPickingLine={setCurrentPickingLine}
            />
            {
                pendingOrders?.length > 0 && <ModalAssignOrderNumberEgreso
                    currentPicking={currentPicking}
                    setCurrentPicking={setCurrentPicking}
                />
            }
        </>
    )
}

export default TableOfPickingForValidationEgreso