import { useContext, useEffect, useState } from 'react'
import { Form, Modal, Button } from "react-bootstrap"
import PickingContext from '../../context/pickingContext'
import { editPickingLineQuantity } from './services/editPickingLineQuantity'
import Swal from 'sweetalert2'

const ModalEditQuantityLine = ({ currentPickingLine, setCurrentPickingLine }) => {

    const { pickingDetail = [], setPickingDetail } = useContext(PickingContext)
    const [validated, setValidated] = useState(false)
    const [newQuantity, setNewQuantity] = useState('')

    const editPickingLineQuantityHandler = async () => {
        const { message = '' } = await editPickingLineQuantity({ pickingID: currentPickingLine?.pickingID, articleQuantity: newQuantity })
        if (message.includes('Successfully')) {
            pickingDetail?.forEach(picking => {
                if (currentPickingLine?.pickingID === picking.pickingID) picking.articleQuantity = newQuantity
            })
            setPickingDetail([...pickingDetail])
            setCurrentPickingLine({})
            Swal.fire({
                icon: 'success',
                title: 'Edición de línea exitosa'
            })
            return
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        }
        setValidated(true)
        editPickingLineQuantityHandler()
    }

    useEffect(() => {
        setNewQuantity(currentPickingLine?.articleQuantity)
    }, [currentPickingLine?.articleQuantity])

    return (
        <Modal
            size="sm"
            show={Object.keys(currentPickingLine).length > 0}
            onHide={() => setCurrentPickingLine({})}
            centered
        >
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit}
                    noValidate
                    validated={validated}
                >
                    <Form.Group controlId="validationCustom01">
                        <Form.Label>Item: {currentPickingLine?.ItemCode}</Form.Label>

                        <Form.Control
                            type="text"
                            placeholder="Cant. Productos"
                            value={newQuantity}
                            onChange={e => setNewQuantity(e.target.value)}
                            required
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalEditQuantityLine
