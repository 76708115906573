export const getOriginWarehouse = async ({ BPLID }) => {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING
    const params = new URLSearchParams({ BPLID }).toString()

    const resultGetOriginWarehose = await window.fetch(`${requestPath}/back/routes/warehouses.php?${params}`, {
        credentials: 'include'
    })

    const { result = [], message = '' } = await resultGetOriginWarehose.json()
    return { result, message }
}
