import { Col, Form, InputGroup } from 'react-bootstrap'
import { useState } from 'react'
import { getBusinessData } from './services/getBusinessData'
import Notiflix from 'notiflix'


function FormGetSupplier({ setResultBusinessHandler }) {

    const [referenceValue, setReferenceValue] = useState({
        type: 'CUIT',
        reference: ''
    })

    const onSubmitHandler = async (e) => {
        e.preventDefault()
        Notiflix.Block.dots(".modal-body", "Buscando Proveedores...")
        const { result } = await getBusinessData({ ...referenceValue })
        setResultBusinessHandler(result)
        Notiflix.Block.remove(".modal-body")
    }

    return (
        <Form onSubmit={onSubmitHandler}>
            <Form.Group controlId="formBasicEmail">
                <Form.Label className="text-center">Buscar Proveedor</Form.Label>
                <Col md={8}>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <Form.Control as="select"
                                defaultValue="Elegir..."
                                size="sm"
                                onChange={(e) => setReferenceValue({ ...referenceValue, type: e.target.value })}
                            >
                                <option value="CUIT">CUIT</option>
                                <option value="Cod">Cod. Cliente</option>
                                <option value="Nombre">Nombre Proveedor</option>
                            </Form.Control>
                        </InputGroup.Prepend>
                        <Form.Control
                            placeholder="Buscar"
                            onChange={(e) => setReferenceValue({ ...referenceValue, reference: e.target.value })}
                            size="sm"
                            value={referenceValue?.reference}
                            className="mx-1"
                        />
                    </InputGroup>
                </Col>
            </Form.Group>
        </Form>
    )
}

export default FormGetSupplier