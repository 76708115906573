import { actionsPicking } from './actionsPicking'

export const pickingReducer = (state, action) => {
    const { payload, type } = action
    switch (type) {

        case actionsPicking.ADD_PICKING:
            const { pickingValue, pickingQuantity, ...rest } = payload
            return {
                ...state,
                picking: {
                    ...state?.picking,
                    [pickingValue]: {
                        ...state?.picking?.[pickingValue],
                        quantity: pickingQuantity,
                        ...rest
                    }
                }
            }

            case actionsPicking.ADD_PICKING_EDIT:
                const { pickingValueEdit, pickingQuantityEdit, ...resto } = payload
                return {
                    ...state,
                    pickingEditable: {
                        ...state?.pickingEditable,
                        [pickingValueEdit]: {
                            ...state?.pickingEditable?.[pickingValueEdit],
                            quantity: pickingQuantityEdit,
                            ...resto
                        }
                    }
                }



        case actionsPicking.REMOVE_PICKING:
            return {
                ...state,
                picking: payload
            }
        case actionsPicking.TYPE_SEARCH:
            return {
                ...state,
                searchType: payload
            }
        case actionsPicking.CONFIRM_PICKING:
            return {
                ...state,
                pickingOutput: payload
            }
        case actionsPicking.FOR_VALIDATION_PICKING:
            return {
                ...state,
                pickingForValidation: payload
            }
        case actionsPicking.PICKINGFOUND:
            return {
                ...state,
                pickingFound: payload
            }
        case actionsPicking.PICKINGDETAIL:
            return {
                ...state,
                pickingDetail: payload
            }
            case actionsPicking.EDITABLE_PICKING:
            return {
                ...state,
                pickingEditable: payload
            }
        case actionsPicking.SET_SUPPLIERDATA:
            return {
                ...state,
                supplierData: payload
            }
        case actionsPicking.SET_PENDINGORDERS:
            return {
                ...state,
                pendingOrders: payload
            }
        case actionsPicking.SET_LINESFORDOCUMENTLINES:
            return {
                ...state,
                linesForDocumentLines: { ...state.linesForDocumentLines, ...payload }
            }
        case actionsPicking.CLEAR_LINESFORDOCUMENTLINES:
            return {
                ...state,
                linesForDocumentLines: {}
            }
        case actionsPicking.SET_FORMATEDENTRYITEMS:
            return {
                ...state,
                formatedEntryItems: payload
            }
        case actionsPicking.SET_CANCELPICKINGDATA:
            return {
                ...state,
                cancelPicking: payload
            }
        case actionsPicking.SET_PICKINGSELECTED:
            return {
                ...state,
                selectedPicking: payload
            }
        case actionsPicking.TYPE_PICKING:
            return {
                ...state,
                pickingType: payload
            }
        case actionsPicking.SET_ORIGINWAREHOUSES:
            return {
                ...state,
                originWarehouse: payload
            }
        case actionsPicking.SET_DESTINYWAREHOUSES:
            return {
                ...state,
                destinyWarehose: payload
            }
        case actionsPicking.SET_SELECTEDORIGINWAREHOUSE:
            return {
                ...state,
                selectedOriginWarehouse: payload
            }
        case actionsPicking.SET_SELECTEDDESTINYWAREHOUSE:
            return {
                ...state,
                selectedDestinyWarehouse: payload
            }
        case actionsPicking.SET_POINTOFISSUECODE:
            return {
                ...state,
                pointsOfIssueCodes: payload
            }
        case actionsPicking.SET_SELECTEDPOINTOFISSUECODE:
            return {
                ...state,
                selectedPointOfIssueCode: payload
            }


        default:
            return state
    }
}