import { useContext, useRef, useEffect } from "react"
import { Col, Form, Row } from "react-bootstrap"
import PickingContext from "../../context/pickingContext"
import ModalComponent from "../ModalComponent"
import { useCookies } from 'react-cookie'
import Swal from 'sweetalert2'

function ModalAssignOrderNumber({ currentPicking, setCurrentPicking }) {

    const { pendingOrders = [], setPendingOrders, linesForDocumentLines = {}, setLinesForDocumentLines } = useContext(PickingContext)

    const [cookies] = useCookies()
    const checkboxRef = useRef(null);

  


    const handleCloseModal = () => {
        const existSelectedPendingOrders = pendingOrders.filter(({ NroPedido, DocEntry, ItemCode }) => `${NroPedido}-${DocEntry}-${ItemCode}` in linesForDocumentLines)
        if (existSelectedPendingOrders.length > 0) {
            let cantidad = 0
            pendingOrders.map(({ NroPedido, DocEntry, ItemCode }) => {
                if (`${NroPedido}-${DocEntry}-${ItemCode}` in linesForDocumentLines) {
                    if (linesForDocumentLines[`${NroPedido}-${DocEntry}-${ItemCode}`].Quantity == 0) {
                        Swal.fire({
                            icon: "error",
                            title: "Acción denegada",
                            text: "No se puede asignar valor 0"
                        })
                        return
                    } else cantidad += linesForDocumentLines[`${NroPedido}-${DocEntry}-${ItemCode}`].Quantity
                }
            })
            if (cantidad == currentPicking.articlePending) {
                setPendingOrders([])
                setCurrentPicking({})
            } else Swal.fire({
                icon: "error",
                title: "Acción denegada",
                text: "La cantidad a ingresar debe ser igual a la del picking"
            })
        } else {
            setPendingOrders([])
            setCurrentPicking({})
        }
    }

    console.log('pendingOrders', pendingOrders[0].OpenQty)
    return (
        <ModalComponent
            modalIsVisible={pendingOrders.length > 0}
            handleClose={handleCloseModal}
            keyboard={false}
            showConfirmButton={false}
            backdrop="static"
            modalTitle="Seleccionar N° Pedido o Factura de Reserva"
        >
            <Row className="justify-content-end m-2">
                <p className="mb-0"> Cant. Picking: <span className="font-weight-bold"> {currentPicking?.articleQuantity}</span></p>
            </Row>
            <Row>
                {
                    pendingOrders
                        .map(order => <Row key={order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode} className="border rounded my-1 mx-3 p-1 justify-content-between w-100 px-3 align-items-center">
                            <Col md={3} className="px-0">
                                <Row className="px-2">
                                    {pendingOrders.length > 1 && <Form.Check
                                        inline
                                        type="checkbox"
                                        onClick={e => {
                                            if (linesForDocumentLines[Object.keys(linesForDocumentLines).filter(val => val).shift()]?.BaseType == order.ObjType) {
                                                if (e.target.checked) {
                                                    setLinesForDocumentLines({
                                                        ...linesForDocumentLines,
                                                        [order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode]: {
                                                            ItemCode: order?.ItemCode,
                                                            Quantity: 0,
                                                            WarehouseCode: cookies?.BPLName,
                                                            ProjectCode: order?.Project,
                                                            BaseType: Number(order?.ObjType),
                                                            BaseEntry: order?.DocEntry,
                                                            BaseLine: order?.LineNum,
                                                        }
                                                    })
                                                } else {
                                                    delete linesForDocumentLines?.[order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode]
                                                    setLinesForDocumentLines(linesForDocumentLines)
                                                }
                                            } else {
                                                if (Object.keys(linesForDocumentLines).length === 0) {
                                                    setLinesForDocumentLines({
                                                        ...linesForDocumentLines,
                                                        [order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode]: {
                                                            ItemCode: order?.ItemCode,
                                                            Quantity: 0,
                                                            WarehouseCode: cookies?.BPLName,
                                                            ProjectCode: order?.Project,
                                                            BaseType: Number(order?.ObjType),
                                                            BaseEntry: order?.DocEntry,
                                                            BaseLine: order?.LineNum,
                                                        }
                                                    })
                                                } else {
                                                    e.preventDefault()
                                                }
                                            }
                                        }}
                                        disabled={Object.keys(linesForDocumentLines).length > 0 ? linesForDocumentLines[Object.keys(linesForDocumentLines).filter(val => val).shift()]?.BaseType != order.ObjType : false}
                                        checked={`${order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode}` in linesForDocumentLines}
                                    />}
                                    <p className="mb-0">N° {order.Tipo}: {order.ObjType == "18" ? order.NroFactura : order.NroPedido}</p>
                                </Row>
                            </Col>
                            <Col md={3} className="px-0">
                                <p className="mb-0">Cant. Pendiente: {order.OpenQty}</p>
                            </Col>
                            <Col md={3} className="px-0">
                                <p className="mb-0">Ref. Pedido Prov: {order?.U_NumRefProv ?? "-"}</p>
                            </Col>
                            {
                               pendingOrders.length === 1 ?
                                    <p className="mb-0">Cant. a Ingresar: {currentPicking?.articleQuantity}</p>
                                    :
                                    <Col md={2} className="px-0">
                                        <Form.Control
                                            size="sm"
                                            placeholder="Asignar Cantidad"
                                            value={linesForDocumentLines?.[order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode]?.Quantity ?? 0}
                                            disabled={!(!!linesForDocumentLines?.[order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode])}
                                            onChange={e => {
                                                if ([order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode] in linesForDocumentLines) {
                                                    const selectedOrder = pendingOrders?.filter(({ NroPedido, NroFactura, Tipo }) => NroPedido === order.NroPedido && NroFactura === order.NroFactura && Tipo === order.Tipo)
                                                    if (selectedOrder?.[0]?.OpenQty < Number(e.target.value)) {
                                                        e.preventDefault()
                                                        Swal.fire({
                                                            icon: "warning",
                                                            title: "Acción denegada",
                                                            text: "No puedes ingresar un valor mayor a la cantidad Pendiente"
                                                        })
                                                    } else {
                                                        linesForDocumentLines[order.NroPedido + "-" + order.DocEntry + '-' + order.ItemCode].Quantity = Number(e.target.value)
                                                    }

                                                }
                                                setLinesForDocumentLines(linesForDocumentLines)
                                            }}
                                            onKeyPress={e => {
                                                if (e.code.includes('Key') && e.ctrlKey === false) return e.preventDefault()
                                            }
                                            }
                                        />
                                    </Col>
                            }

                        </Row>
                        )
                }
                {
                    pendingOrders.length === 0 && <div className="w-100"><p className="mb-0 text-center">No hay datos que mostrar</p></div>
                }
            </Row>
        </ModalComponent>
    )
}

export default ModalAssignOrderNumber