import { Divider } from 'antd'
import { memo, useContext, useRef, useState } from 'react'
import { Container, Row, Button } from 'react-bootstrap'
import pickingContext from '../../context/pickingContext'
import ToastComponent from '../ToastComponent'
import FormGetItemEdit from './FormGetItemEdit'
import PickingTableEdit from './PickingTableEdit'
import { savePickingEdit } from './services/savePickingEdit'
import SwitchPickingType from './SwicthPickingType'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'


function PickingTabEdit() {

    const toastRef = useRef(null)
    const history = useHistory()

    const [loadingTable, setLoadingTable] = useState(false)
    const [show, setShow] = useState(false)
    const [toastMessage, setToastMessage] = useState({})

    const { pickingEditable = {}, removeCurrentPickingHandler, pickingType } = useContext(pickingContext)

    const pickingCode = window.location.href.split('/').pop()
    console.log('pickingCode', pickingCode)

    return (
        <Container className="p-2 p-md-5 rounded" id="tab-container">
                    <p>Picking N° <b>{pickingCode}</b></p>

            <ToastComponent
                show={show}
                setShow={() => setShow(false)}
                message={toastMessage?.message}
                title={toastMessage?.title}
                color={toastMessage?.color}
                reference={toastRef}
            />
            {/* <SwitchPickingType /> */}
            <FormGetItemEdit setLoadingTable={setLoadingTable} />
            <Divider />
            <PickingTableEdit loadingTable={loadingTable} />
            {
                Object.keys(pickingEditable).length > 0 &&
                <Row className="justify-content-center mt-3">

                    <Button variant="success" onClick={async () => {
                        const { message = "", error_catch = '' } = await savePickingEdit({ pickingEditable,pickingCode,pickingType })
                        if (message.includes("Successfully")) {
                            setShow(true)
                            Swal.fire({
                                icon: 'success',
                                title: 'Productos actualizados correctamente',
                                text: `Se actualizó el picking N° ${pickingCode}`
                            })
                            history.push(`/consultasExit/`)

                            toastRef.current?.scrollIntoView(true, { behavior: "smooth" })
                            removeCurrentPickingHandler('')
                        } else Swal.fire({
                            icon: 'error',
                            title: 'Ocurrió un error',
                            text: `No se pudo ingresar el pickingEditable. Error: ${error_catch}`
                        })

                    }} >Actualizar Picking</Button>

                </Row>
            }
        </Container>
    )
}


export default memo(PickingTabEdit)