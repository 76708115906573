import { useContext } from 'react'
import TableComponent from '../TableComponent'
import PickingContext from '../../context/pickingContext'
import { pickingStatus } from '../utils/pickingStatus'
import ActionsForTableConsult from './actionsForTableConsult'
import { useParams } from 'react-router-dom'
import { useCookies } from 'react-cookie'

function ConsultTable({ loadingTable }) {

    const { pickingFound = [] } = useContext(PickingContext)
    const params = useParams()
    const [cookies] = useCookies()


    const dictionary = {
        pickingCode: 'Cod. Picking',
        brandName: 'Sucursal',
        userName: 'Usuario',
        pickingType: 'Tipo',
        pickingStatus: 'Estado',
        fullDatePicking: 'Fecha Picking'
    }

    const columns = Object.entries(dictionary)
        .map(([accessKey, label]) => ({
            title: label,
            dataIndex: accessKey,
            key: accessKey,
            width: accessKey === 'brandName' ? '30%' : '20%',
            align: 'center',
            ellipsis: accessKey === 'brandName' ? true : false
        }));

    return (
        <>
            <TableComponent columns={columns}
                scroll={{ x: true }}
                keyTable="pickingCode"
                data={pickingFound
                    .filter(picking => params?.typeSearch === 'Todos' && picking.brandCode === cookies?.BPLName ? true : picking.pickingStatus === params?.typeSearch&& picking.brandCode === cookies?.BPLName)
                }
                action={[
                    {
                        title: 'Acción',
                        align: 'center',
                        width: '10%',
                        render: rowData => <ActionsForTableConsult rowData={rowData} />
                    }]
                }
                addSearch={pickingFound?.length > 5}
                loading={loadingTable}
            />
        </>
    )
}

export default ConsultTable