export const loginHandler = async ({ userName, password }) => {
    const requestPath = process.env.REACT_APP_APPSTATEMENT !== "development" ? process.env.REACT_APP_BASEURL_PRODUCTION : process.env.REACT_APP_BASEURL_DEVELOPING
    const loginResult = await window.fetch(`${requestPath}/back/routes/auth.php`, {
        method: 'POST',
        body: JSON.stringify({ userName, password }),
    }).catch(error => console.log("ocurrió un error", error))

    const { status, result = [], error_msg = "" } = await loginResult.json()

    return { status, result, error_msg }
}