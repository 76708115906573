export const actionsPicking = {
    ADD_PICKING: 'add-picking',
    ADD_PICKING_EDIT: 'add-picking-edit',
    EDITABLE_PICKING:'edit-picking',
    REMOVE_PICKING: 'remove-picking',
    TYPE_SEARCH: 'type-search',
    TYPE_PICKING: 'type-picking',
    QUANTIYY_PICKING: 'quantity-picking',
    CONFIRM_PICKING: 'confirm-picking',
    FOR_VALIDATION_PICKING: 'for-validacion-picking',
    PICKINGFOUND: "piciking-found",
    PICKINGDETAIL: "picking-Detail",
    SET_SUPPLIERDATA: "set-supplierData",
    SET_PENDINGORDERS: "set-pendingOrders",
    SET_LINESFORDOCUMENTLINES: "set-documentLines",
    CLEAR_LINESFORDOCUMENTLINES: "clear-linesForDocumentLines",
    SET_FORMATEDENTRYITEMS: "set-formatedData",
    SET_CANCELPICKINGDATA: "set-cancelPickingData",
    SET_PICKINGSELECTED: 'set-pickingSelected',
    SET_ORIGINWAREHOUSES: 'set-originWarehouses',
    SET_DESTINYWAREHOUSES: 'set-destinyWarehouses',
    SET_SELECTEDORIGINWAREHOUSE: 'set-selectedOriginWarehouse',
    SET_SELECTEDDESTINYWAREHOUSE: 'set-selectedDestinyWarehouse',
    SET_POINTOFISSUECODE: 'set-pointOfIssueCode',
    SET_SELECTEDPOINTOFISSUECODE: 'set-selectedPointOfIssueCode',
}