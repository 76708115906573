import Notiflix from "notiflix"
import { useContext } from "react"
import { Button, Row } from "react-bootstrap"
import { useHistory } from "react-router-dom"
import Swal from "sweetalert2"
import PickingContext from "../../context/pickingContext"
import { useFormatTransferOrder2 } from "../../hooks/useFormatTransferOrder2"
import { editPicking_010 } from '../consultTab/services/editPicking_010'
import { postPickingTransfer_exit } from "./services/postPickingTransfer_exit"

function ButtonSavePickingExit2() {

    const history = useHistory()
    const { pickingFound = [], setPickingFound, pickingDetail = [], selectedDestinyWarehouse = {}, selectedOriginWarehouse = '', selectedPicking = {}, selectedPointOfIssueCode = '', setSelectedPointsOfIssueCode, setSelectedOriginWarehouse, setSelectedDestinyWarehouse } = useContext(PickingContext)

    const { formatedTransferOrder } = useFormatTransferOrder2({ pickingDetail, selectedDestinyWarehouse, selectedOriginWarehouse, selectedPointOfIssueCode })
    console.log("file: ButtonSavePickingExit2.js ~ line 17 ~ pickingDetail", pickingDetail)
    console.log('formatedTransferOrder', formatedTransferOrder);

    const allPickingQuantityOk = pickingDetail.filter(article => article.articleStatus === '1')
        .every(picking => picking?.articleQuantity <= picking?.InStockQuantity)

    const savePickingTransferHandler = async () => {
        Notiflix.Block.dots('#tab-container', 'Cargando Transferencia de Stock...')
        const { result = [], message = '', error_msg = '' } = await postPickingTransfer_exit(formatedTransferOrder)
        if (message.includes('Successfully') && result.length > 0) {
            const { message = '' } = await editPicking_010({ entrySAP: result[0]?.DocEntry,DocNum: result[0]?.DocNum, pickingCode: selectedPicking?.pickingCode})
            if (message.includes('Successfully')) {
                const newPickingsFound = pickingFound.map(picking => picking?.pickingCode === selectedPicking?.pickingCode && (picking.pickingStatus = 'Ingresado'))
                setPickingFound(newPickingsFound)
            }
            Swal.fire({ title: 'Transferencia Exitosa', text: 'La transferencia de Stock se realizó Correctamente' })
            setSelectedPointsOfIssueCode('')
            setSelectedOriginWarehouse('')
            setSelectedDestinyWarehouse({})
            history.go('/consultas')
        }
        if (!!error_msg) Swal.fire({ title: 'Ocurrió un error', text: error_msg })
        Notiflix.Block.remove('#tab-container')
    }

    const { CardCode = '', FromWarehouse = '', PointOfIssueCode = '' } = formatedTransferOrder

    return (
        <Row className="justify-content-end mx-0">
            {
                allPickingQuantityOk && !!CardCode && !!FromWarehouse && !!PointOfIssueCode &&
                <Button
                    variant="primary"
                    onClick={savePickingTransferHandler}
                >
                    Guardar
                </Button>
            }
        </Row>
    )
}

export default ButtonSavePickingExit2