import React, { useState } from 'react'
import 'antd/dist/antd.css'
import { Table, Input, Button, Space } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
// import FooterTable from './FooterTable'

function TableComponent({ data = [], columns = [], action = [], keyTable, addSearch, loading, FooterTable }) {

    let searchInput
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const getColumnSearchProps = (dataIndex, title) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        searchInput = node
                    }}
                    placeholder={`Buscar ${title}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Borrar
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false })
                            setSearchText(selectedKeys[0])
                            setSearchedColumn(dataIndex)
                        }}
                    >
                        Filtrar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => {
            return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        },
        onFilter: (value, record) => {
            return record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : ''
        },
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100)
            }
        },
        render: text => {
            return (
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                    />
                ) : (
                    text
                )
            )
        },
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)

    }

    const handleReset = clearFilters => {
        clearFilters()
        setSearchText('')
    }

    let columnsConstructor = []
    if (columns.length > 0) {
        columnsConstructor = [...columns].map(col => { return { ...col, ...getColumnSearchProps(col.dataIndex, col.title) } })
    }

    return <Table
        rowClassName={() => `text-nowrap`}
        columns={Object.keys(action).length > 0 && columnsConstructor.length > 0 ? [...columnsConstructor, ...action] : Object.keys(action).length === 0 && columnsConstructor.length > 0 && addSearch ? columnsConstructor : columns}
        dataSource={data}
        pagination={{ position: ['none', 'bottomCenter'], pageSize: 10 }}
        scroll={{ y: 240, x: true }}
        size="small"
        bordered={true}
        rowKey={keyTable}
        loading={loading}
        footer={(props) => FooterTable ? <FooterTable data={props} /> : null}
    />
}

export default TableComponent